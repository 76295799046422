import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingSpinner({ state, children }) {
  return (
    <>
      <div className={state && " opacity-60 pointer-events-none bg-white"}>
        {state && (
          <CircularProgress
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              zIndex: 5,
            }}
          />
        )}
        {children}
      </div>
    </>
  );
}
