import React from "react";
import Button from "../../../../components/Button/Button";
import Stepper from "../../../../components/Stepper/MainStepper";
import One from "../../../../icons/numbers/1.svg";
import Two from "../../../../icons/numbers/2.svg";
import Three from "../../../../icons/numbers/3.svg";
import Threatening from "../../../../icons/threatening.svg";

export default function CultureInformation({ changePage }) {
  //initial
  //services
  //states
  return (
    <div className="p-5 flex justify-center align-middle flex-col items-center text-left w-full">
      <div className="w-[1136px]">
        <span className="text-neutral-500 text-lg font-semibold font-['Outfit']">
          İnovasyon Yetkinlik Değerlendirmesi
        </span>
        <span className="text-neutral-500 text-lg font-normal font-['Outfit']">
          {" "}
          ile birlikte çalışanlarınız tarafından algılanan İnovasyon
          Kültürünüzün de ölçülmesi oldukça önemlidir. İnovasyon Kültürü
          Değerlendirmesi’ni, İnovasyon Yetkinlik Değerlendirmesi’nden farklı
          olarak ilgili tüm firma çalışanları tarafından doldurulmasını tavsiye
          ediyoruz. Örneğin İK, üretim, pazarlama, ar-ge ve strateji gibi
          departman çalanlarını bu anketin doldurulması sürecine dahil
          olabilirler. En az 30 çalışanınızın bu konuda görüşünü almanızı ve
          anketi doldurmalarını tavsiye ederiz.{" "}
        </span>
      </div>
      <br />

      <br />
      <div className="w-[1136px] text-neutral-500 text-lg font-normal font-['Outfit']">
        İnovasyon kültürü, bir organizasyonun inovasyonu teşvik eden değerler,
        inançlar, davranışlar ve uygulamaların birleşimi olarak tanımlanabilir.
        Bu kültür, çalışanların yaratıcılığı teşvik ettiği, risk alma ve
        değişime uyum sağlama konusunda cesaretlendirildiği bir ortam oluşturur.
        Eğer böyle bir ortam oluşmaz ise inovasyon yeteneklerinden,
        yetkinliklerinden ve sonuçlarından bahsetmemiz pek mümkün olmaz.
        Öncelikli olarak elverişli bir ortamın hazırlanması ve inovasyona niyet
        edilmesi gerekmektedir. Bu yüzden İnovasyon Kültürü en az İnovasyon
        Yetkinliği kadar önemli bir konu başlığıdır.{" "}
      </div>
      <br />

      <div className="w-[1117px] h-[69px] bg-orange-100 rounded-[11px] mt-5 flex items-center ">
        <img className="p-4" src={Threatening} />
        <div className="w-[961px] text-[#2977A0] text-lg font-semibold font-['Outfit']">
          Kurumsal Girişim Pusulası ile de bu alanda sizlere bir anket sunarak
          öz değerlendirmenizi yapmanızı sağlıyoruz. İnovasyon Kültürü Anketi, 3
          temel boyuttan oluşmaktadır:{" "}
        </div>
      </div>
      <br />
      <div className="w-[1136px] flex items-start">
        <img className="p-2" src={One} />
        <div>
          <span className="text-neutral-500 text-lg font-semibold font-['Outfit']">
            Değerler:{" "}
          </span>
          <span className="text-neutral-500 text-lg font-normal font-['Outfit']">
            Değerler, belirsizlik ve başarısızlık karşısında hoşgörülü, eylem
            odaklı, yaratıcı ve öğrenme kültürüne sahip bir organizasyonu temsil
            eder. Anket ile bu boyutta yeni fırsat ve farklı bakış açılarına
            firma içinde ne kadar önem verildiği, öz eleştiri ve denemelere
            firmanın ne kadar açık olduğu ölçülmeye çalışılmaktadır.{" "}
          </span>
        </div>
      </div>
      <br />
      <div className="w-[1136px] flex items-start">
        <img className="p-2" src={Two} />
        <div>
          <span className="text-neutral-500 text-lg font-semibold font-['Outfit']">
            Davranışlar:{" "}
          </span>
          <span className="text-neutral-500 text-lg font-normal font-['Outfit']">
            Davranışlar, insanların alışkanlıklarını ve inovatif olabilmek için
            nasıl hareket ettiklerini gösterir. Çalışanlar arasındaki sinerji ve
            yöneticilerin hizmet liderleri olarak davranışları, inovasyon
            kültürünün davranış boyutunun ana konularını oluşturur. Anket ile
            yöneticilerin inovasyon faaliyetlerine karşı tutumu ölçülmeye
            çalışılmaktadır.{" "}
          </span>
        </div>
      </div>
      <br />
      <div className="w-[1136px] flex items-start">
        <img className="p-2" src={Three} />
        <div>
          <span className="text-neutral-500 text-lg font-semibold font-['Outfit']">
            Çalışma İklimi:
          </span>
          <span className="text-neutral-500 text-lg font-normal font-['Outfit']">
            İklim, işletme bürokrasisinin derecesini, çalışma koşullarındaki
            esnekliği ve çalışanlar arasındaki işbirliği ve iletişim derecesini
            içeren bir kurumsal ortamı ifade eder. Anket ile firma çalışma
            ortamının ne kadar yenilikçi ve işbirliğine açık bir ortam olduğu
            ölçülmeye çalışılmaktadır.{" "}
          </span>
        </div>
      </div>
      <br />
      <div className="w-[1136px] text-neutral-500 text-lg font-normal font-['Outfit']">
        Anketi doldurarak bu üç boyuttaki skorunuzu 1-5 arasında görebilirsiniz.
        Ayrıca zaman içerisinde kat ettiğiniz gelişmelere bağlı olarak anketi
        tekrar tekrar gözden geçirmenizi öneririz.{" "}
      </div>

      <div className="w-full flex items-center ">
        <div className="w-[600px] flex-1">
          <div className="w-full pr-10 ">
            <Stepper nextChange={changePage} activeStep={1} stepLimit={3} />
          </div>
        </div>
      </div>
    </div>
  );
}
