import React from "react";
import Pencil from "../../../../icons/pencil.svg";
import Stepper from "../../../../components/Stepper/MainStepper";

export default function Information({ changePage }) {
  return (
    <div>
      <div className="w-[1136px] text-black text-lg font-semibold font-['Outfit']">
        Değerli Firma Yetkilimiz,
      </div>
      <br />
      <div className="w-[1136px] text-neutral-500 text-lg font-normal font-['Outfit']">
        İnovasyon fırsatlarını keşfetmek için başvurmanız gereken en önemli
        kaynaklardan biri kullanıcılarınız ve müşterilerinizdir.
        <br />
        İnovasyonlar genellikle onlar için yapılır ve paydaşlarınız içerisinde
        ayrı bir öneme sahiptirler. Onların gerçekte neye ihtiyacı olduğunu
        tespit edebilmek oldukça önemlidir. Bu yüzden kullanıcı ve
        müşterilerinizin gerçek problemini, ihtiyaçlarını analiz etmeniz
        gerekir. Sizlere sunduğumuz tasarım odaklı düşünme şablonları ve
        yönlendirici içeriklerimiz ile bu modüllerde müşterilerinizi ve onların
        problemini doğru analiz etmenizi sağlıyor, bu konuda yenilikçi fikirler
        üretmenizi ve bu fikirleri doğru değerlendirmenize destek sunuyoruz.
        Tasarım Odaklı Düşünme ve şablonlarını kullanma konusunda şayet
        yeterince deneyime sahip değilseniz Girişim Pusulası olarak bizlerle
        iletişime geçmenizi öneririz.
      </div>
      <br />
      <div className="items-center  text-neutral-500 text-lg font-semibold font-['Outfit'] flex">
        <img className="p-1 pr-2" src={Pencil} />
        Haydi gelin şimdi başlayalım!
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-neutral-500 text-lg font-normal font-['Outfit']">
          En önemli gördüğünüz problemler ve bu problemlerin ilgili
          olduğu/etkilediği müşteri veya kullanıcı kitleleriniz kimler? Birden
          çok önemli gördüğünüz problem ve bu problemlerin etkilediği farklı
          müşteri veya kullanıcılarınız olabilir; ama en öncelikli çözülmesi
          gerektiğini gördüğünüz bir problem ve etkilediği bir kullanıcı
          kitlesini belirleyerek probleminizi aşağıdaki formatta tanımlamaya
          başlayabilirsiniz. Örneğin inşaat ve mimarlık sektöründe iseniz önemli
          gördüğünüz bir probleminiz “
        </span>
        <span className="text-neutral-500 text-lg font-semibold font-['Outfit']">
          3-10 yaş arası çocuklar için özelleştirilmiş mimari tasarım proje
          çizimlerini ve uygulamalarını nasıl yapabiliriz ki böylelikle
          çocukların otel konaklama deneyimlerini daha eğlenceli hale
          getirebiliriz?
        </span>
        <span className="text-neutral-500 text-lg font-normal font-['Outfit']">
          ” olabilir. Şimdi gelin bu örneğe benzer probleminizi orta satıra,
          problemin etkilediği kullanıcı kitlesini ilk satıra, probleminizi
          çözdüğünüzde yaratacağı etkiyi son satıra yazarak başlayınız.
        </span>
      </div>
      <div className="w-full flex items-center ">
        <div className="w-[600px] flex-1">
          <div className="w-full pr-10 ">
            <Stepper nextChange={changePage} activeStep={1} stepLimit={3} />
          </div>
        </div>
      </div>
    </div>
  );
}
