import HomeIcon from "../../icons/home.svg";
import InovationIcon from "../../icons/inovation.svg";
import CustomerProblemIcon from "../../icons/customer-problem.svg";
import IntrapreneurshipIcon from "../../icons/intrapreneurship.svg";
import StrategicPlanIcon from "../../icons/strategic-plan.svg";
import MarketResearchIcon from "../../icons/market-research.svg";
import DigitalMarketingIcon from "../../icons/digital-marketing.svg";
////
import InovationBlueIcon from "../../icons/inovation-blue.svg";
import CustomerProblemBlueIcon from "../../icons/customer-problem-blue.svg";
import IntrapreneurshipBlueIcon from "../../icons/intrapreneurship-blue.svg";
import StrategicPlanBlueIcon from "../../icons/strategic-plan-blue.svg";
import MarketResearchBlueIcon from "../../icons/market-research-blue.svg";
import DigitalMarketingBlueIcon from "../../icons/digital-marketing-blue.svg";

//
import InovationCompetenceAssessment from "../../icons/subIcons/innovation-competence-assessment.svg";
import InovationCultureAssessment from "../../icons/subIcons/innovation-culture-assessment.svg";
//
import Empathy from "../../icons/subIcons/empathy.svg";
import ProblemDefinition from "../../icons/subIcons/problem-definition.svg";
import IdeaGenerationAndEvaluation from "../../icons/subIcons/idea-generation-and-evaluation.svg";
//
import DeterminingValueInnovation from "../../icons/subIcons/determining-value-innovation.svg";
import DeterminationOfMarketAttractiveness from "../../icons/subIcons/determination-of-market-attractiveness.svg";
import MarketFitTest from "../../icons/subIcons/market-fit-tests.svg";
import BussinessModelDesign from "../../icons/subIcons/business-model-design.svg";
//
import StakeholderAnalyis from "../../icons/subIcons/stakeholder-analysis.svg";
import İnternalEnviromentAnalysis from "../../icons/subIcons/internal-environment-analysis.svg";
import MarketAnalysis from "../../icons/subIcons/market-analysis.svg";
import EnvironmentalAnalysis from "../../icons/subIcons/environment-analysis.svg";
import SwotAnalysis from "../../icons/subIcons/swot-analysis.svg";
import StaretegicDirection from "../../icons/subIcons/strategic-direction.svg";
import ProjectPlaning from "../../icons/subIcons/project-planning.svg";
//
import DeterminantionOfExportIntention from "../../icons/subIcons/determination-of-intent-to-export.svg";
import DeterminationOfExportPotantial from "../../icons/subIcons/determination-of-export-potential.svg";
import DeterminationOfMarket from "../../icons/subIcons/determination-of-market-attractiveness.svg";
import OverseasCustomerFinding from "../../icons/subIcons/overseas-customer-finding.svg";

export const MENU_ITEMS = [
  {
    id: 0,
    title: "Anasayfa",
    src: HomeIcon,
    navigation: "/anasayfa",
    focus: false,
    lock: true,
  },
  {
    id: 1,
    title: "İnovasyon Check-Up",
    srcHome: InovationBlueIcon,
    src: InovationIcon,
    focus: false,
    lock: true,
    open: false,
    subMenu: [
      {
        id: 10,
        title: "İnovasyon Yetkinlik Değerlendirmesi",
        src: InovationIcon,
        focus: false,
        srcHome: InovationCompetenceAssessment,
        navigation: "/inavasyon-yetkinlik",
      },
      {
        id: 11,
        title: "İnovasyon Kültürü Değerlendirmesi",
        src: InovationIcon,
        srcHome: InovationCultureAssessment,
        focus: false,
        navigation: "/inavasyon-kültür",
      },
    ],
  },
  {
    id: 2,
    focus: false,
    title: "Müşteri ve Problem Analizi",
    src: CustomerProblemIcon,
    srcHome: CustomerProblemBlueIcon,
    lock: true,
    // disable: true,
    subMenu: [
      {
        id: 20,
        title: "Empati",
        src: InovationIcon,
        focus: false,
        navigation: "/empati",
        srcHome: Empathy,
      },
      {
        id: 21,
        title: "Problem Tanımlama",
        src: InovationIcon,
        focus: false,
        navigation: "/problem-tanimlama",
        srcHome: ProblemDefinition,
      },
      {
        id: 23,
        title: "Fikir Üretme ve Değerlendirme",
        src: InovationIcon,
        focus: false,
        navigation: "/fikir-üretme-değerlendirme",
        srcHome: IdeaGenerationAndEvaluation,
      },
    ],
  },
  {
    id: 3,
    focus: false,
    title: "İç Girişimcilik",
    src: IntrapreneurshipIcon,
    srcHome: IntrapreneurshipBlueIcon,
    lock: true,
    subMenu: [
      {
        id: 30,
        title: "Değer İnovasyonunun Belirlenmesi",
        src: InovationIcon,
        focus: false,
        navigation: "/değer-inavasyonu-belirlenmesi",
        srcHome: DeterminingValueInnovation,
      },
      {
        id: 31,
        title: "Pazar Çekiciliği Belirlenmesi",
        src: InovationIcon,
        focus: false,
        navigation: "/pazar-cesitliligi-belirlenmesi",
        srcHome: DeterminationOfMarketAttractiveness,
      },
      {
        id: 32,
        title: "Ürün-Pazar Uyumu Testleri",
        src: InovationIcon,
        focus: false,
        navigation: "/pazar-uyum-testleri",
        srcHome: MarketFitTest,
      },
      {
        id: 33,
        title: "İş Modeli Tasarımı",
        src: InovationIcon,
        focus: false,
        navigation: "/is-modeli-tasarimi",
        srcHome: BussinessModelDesign,
      },
    ],
  },
  {
    id: 4,
    focus: false,
    title: "Stratejik Plan",
    src: StrategicPlanIcon,
    srcHome: StrategicPlanBlueIcon,
    lock: true,
    subMenu: [
      {
        id: 40,
        title: "Paydas Analizi",
        src: InovationIcon,
        focus: false,
        navigation: "/paydas-analizi",
        srcHome: StakeholderAnalyis,
      },
      {
        id: 41,
        title: "İç Çevre Analizi",
        src: InovationIcon,
        focus: false,
        navigation: "/ic-cevre-analizi",
        srcHome: İnternalEnviromentAnalysis,
      },
      {
        id: 42,
        title: "Pazar Analizi",
        src: InovationIcon,
        focus: false,
        navigation: "/pazar-analizi",
        srcHome: MarketAnalysis,
      },
      {
        id: 43,
        title: "Dış Çevre Analizi",
        src: InovationIcon,
        focus: false,
        navigation: "/cevre-analizi",
        srcHome: EnvironmentalAnalysis,
      },
      {
        id: 44,
        title: "SWOT Analizi",
        src: InovationIcon,
        focus: false,
        navigation: "/swot-analizi",
        srcHome: SwotAnalysis,
      },
      {
        id: 45,
        title: "Stratejik Yönlendirme",
        src: InovationIcon,
        focus: false,
        navigation: "/stratejik-yonlendirme",
        srcHome: StaretegicDirection,
      },
      {
        id: 46,
        title: "Strateji ve OKR Yol Haritası",
        src: InovationIcon,
        focus: false,
        navigation: "/stratejiveokryolharitasi",
        srcHome: ProjectPlaning,
      },
    ],
  },
  {
    id: 5,
    focus: false,
    title: "Pazar Araştırması ve İhracat",
    src: MarketResearchIcon,
    srcHome: MarketResearchBlueIcon,
    lock: true,
    subMenu: [
      {
        id: 50,
        title: "İhracat Niyetinin Belirlenmesi",
        src: InovationIcon,
        focus: false,
        navigation: "/ihracat-niyetinin-belirlenmesi",
        srcHome: DeterminantionOfExportIntention,
      },
      {
        id: 51,
        title: "İhracat Potansiyelinin Belirlenmesi",
        src: InovationIcon,
        focus: false,
        navigation: "/ihracat-potansiyelinin-belirlenmesi",
        srcHome: DeterminationOfExportPotantial,
      },
      {
        id: 52,
        title: "Yurtdışı Hedef Pazar Belirlenmesi",
        src: InovationIcon,
        focus: false,
        navigation: "/yurtdişi-hedef-pazar-belirlenmesi",
        srcHome: DeterminationOfMarket,
      },
      {
        id: 53,
        title: "Yurtdışı Müşteri Bulma",
        src: InovationIcon,
        focus: false,
        navigation: "/yurtdisi-müsteri-bulma",
        srcHome: OverseasCustomerFinding,
      },
    ],
  },
  {
    id: 6,
    focus: false,
    title: "Dijital Pazarlama",
    src: DigitalMarketingIcon,
    srcHome: DigitalMarketingBlueIcon,
    lock: true,
    subMenu: [
      {
        id: 60,
        title: "İhracat Niyetinin Belirlenmesi",
        src: InovationIcon,
        focus: false,
        navigation: "/empati",
        srcHome: InovationCultureAssessment,
      },
      {
        id: 61,
        title: "İhracat Potansiyelinin Belirlenmesi",
        src: InovationIcon,
        focus: false,
        navigation: "/problem-tanimlama",
        srcHome: InovationCultureAssessment,
      },
      {
        id: 62,
        title: "Yurtdışı Hedef Pazar Belirlenmesi",
        src: InovationIcon,
        focus: false,
        navigation: "/fikir-üretme-değerlendirme",
        srcHome: InovationCultureAssessment,
      },
      {
        id: 63,
        title: "Yurtdışı Müşteri Bulma",
        src: InovationIcon,
        focus: false,
        navigation: "/fikir-üretme-değerlendirme",
        srcHome: InovationCultureAssessment,
      },
    ],
  },
];
