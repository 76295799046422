import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { CiSquareInfo } from "react-icons/ci";
import { MdOutlineAddBox } from "react-icons/md";
import Item from "../Item/Item";
export default function Column({
  columnId,
  column,
  addItem,
  height,
  deleteItem,
}) {
  return (
    <div className="flex-1 h-full " key={columnId}>
      <div className="bg-gray-200 h-[50px] px-1 py-2 border-b-2 border rounded-sm">
        <h2 className="m-2 text-sm flex items-center">
          <div className="flex-1 font-semibold">{column.name} </div>
          <div className="flex">
            <CiSquareInfo className="end text-xl" />
            <MdOutlineAddBox
              onClick={() => addItem(columnId)}
              className="end text-xl"
            />
          </div>
        </h2>
      </div>

      <div className="h-full border-r-2 border-l-2">
        <Droppable droppableId={columnId} key={columnId}>
          {(provided, snapshot) => {
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  minHeight: height,
                  background: snapshot.isDraggingOver
                    ? "lightblue"
                    : "lightgrey",
                }}
              >
                {column.items.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        return (
                          <Item
                            provided={provided}
                            item={item}
                            snapshot={snapshot}
                            columnId={columnId}
                            deleteItem={(id) => deleteItem(id, columnId)}
                          />
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </div>
    </div>
  );
}
