import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "./Column/Column";

import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const taskStatus = {
  keyPartnerships: {
    name: "Temel Ortaklıklar",
    items: [],
  },
  coreActivities: {
    name: "Temel Faliyetler",
    items: [],
  },
  essentialResources: {
    name: "Temel Kaynaklar",
    items: [],
  },
  valueProposition: {
    name: "Değer Önerisi",
    items: [],
  },
  customerRelationship: {
    name: "Müşteri İlişkileri",
    items: [],
  },
  channels: {
    name: "Kanallar",
    items: [],
  },
  customerSegment: {
    name: "Müşteri Segmenti",
    items: [],
  },
  costStructure: {
    name: "Maliyet Yapısı",
    items: [],
  },
  revenueStream: {
    name: "Gelir Akışı",
    items: [],
  },
};

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

export default function Canvas() {
  const [columns, setColumns] = useState(taskStatus);
  const [addColumnId, setAddColumnId] = useState("");
  const [taskTitle, settaskTitle] = useState("");
  const [tastDescription, settastDescription] = useState("");
  const [id, setid] = useState(0);
  function addItem() {
    let newColumns = columns;
    let testTask = {
      id: id + 1 + "",
      title: taskTitle,
      content: tastDescription,
    };
    newColumns[addColumnId].items.push(testTask);
    setid(id + 1);
    setColumns(newColumns);
    handleClose();
  }

  function deleteItem(itemId, columnId) {
    let newColumns = columns;
  }

  function printDocument() {
    const canvas = document.getElementById("canvas");
    html2canvas(canvas).then((canvas) => {
      const image = canvas.toDataURL("image/jpeg", 1.0);
      const doc = new jsPDF("p", "px", "a4");
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      // const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = 40;

      //Footer Text
      let strFooter = "fatiharclk.com";
      doc.setTextColor(100);
      doc.setFontSize(10);
      doc.text(strFooter, pageWidth / 2, pageHeight - 10, { align: "center" });

      //Header Text
      let strHeader = "Business Canvas";
      doc.setTextColor(50);
      doc.setFontSize(15);
      doc.text(strHeader, pageWidth / 2 - 5, 20, { align: "center" });

      //Canvas
      doc.addImage(image, "JPEG", 10, marginY, canvasWidth - 20, canvasHeight);
      downloadjs(image, "download.png", "image/png");
      doc.save("iş-kanvası.pdf");
    });
  }
  const [scrollHeight, setScrollHeight] = useState(
    document.documentElement.scrollHeight
  );
  useEffect(() => {
    setScrollHeight(document.documentElement.scrollHeight);
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (columnId) => {
    setAddColumnId(columnId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className=" w-full">
      <div className="h-[50px]">
        <button onClick={() => printDocument()}>PDF indir</button>
      </div>
      <div
        className=" w-full h-full"
        id="canvas"
        style={{ backgroundColor: "lightgrey" }}
      >
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          <div className="flex w-full h-2/3">
            <div className="flex-1 h-full">
              <Column
                height={(scrollHeight * 2) / 3 - 180}
                addItem={(columnId) => handleClickOpen(columnId)}
                deleteItem={(itemId, columnId) => deleteItem(itemId, columnId)}
                column={columns.keyPartnerships}
                columnId={Object.keys(columns)[0]}
              />
            </div>
            <div className="flex-1">
              <div className="flex-1">
                <Column
                  height={(scrollHeight * 2) / 3 / 2 - 180}
                  addItem={(columnId) => handleClickOpen(columnId)}
                  column={columns.coreActivities}
                  columnId={Object.keys(columns)[1]}
                />
              </div>
              <div className="flex-1 h-1/2">
                <Column
                  height={(scrollHeight * 2) / 3 / 2 - 50}
                  addItem={(columnId) => handleClickOpen(columnId)}
                  column={columns.essentialResources}
                  columnId={Object.keys(columns)[2]}
                />
              </div>
            </div>
            <div className="flex-1 h-full">
              <Column
                height={(scrollHeight * 2) / 3 - 180}
                addItem={(columnId) => handleClickOpen(columnId)}
                column={columns.valueProposition}
                columnId={Object.keys(columns)[3]}
              />
            </div>
            <div className="flex-1">
              <div className="flex-1 h-1/2">
                <Column
                  height={(scrollHeight * 2) / 3 / 2 - 180}
                  addItem={(columnId) => handleClickOpen(columnId)}
                  column={columns.customerRelationship}
                  columnId={Object.keys(columns)[4]}
                />
              </div>
              <div className="flex-1 h-1/2">
                <Column
                  height={(scrollHeight * 2) / 3 / 2 - 50}
                  addItem={(columnId) => handleClickOpen(columnId)}
                  column={columns.channels}
                  columnId={Object.keys(columns)[5]}
                />
              </div>
            </div>
            <div className="flex-1 h-full">
              <Column
                height={(scrollHeight * 2) / 3 - 180}
                addItem={(columnId) => handleClickOpen(columnId)}
                column={columns.customerSegment}
                columnId={Object.keys(columns)[6]}
              />
            </div>
          </div>
          <div className="flex h-1/3">
            <div className="flex-1 h-full">
              <Column
                height={(scrollHeight * 1) / 3 - 70}
                addItem={(columnId) => handleClickOpen(columnId)}
                column={columns.costStructure}
                columnId={Object.keys(columns)[7]}
              />
            </div>
            <div className="flex-1 h-full">
              <Column
                height={(scrollHeight * 1) / 3 - 70}
                addItem={(columnId) => handleClickOpen(columnId)}
                column={columns.revenueStream}
                columnId={Object.keys(columns)[8]}
              />
            </div>
          </div>
        </DragDropContext>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className=" w-[1000px] h-[500px] bg-white  p-4">
          <div>
            <label
              for="first_name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              First name
            </label>
            <input
              onChange={(e) => settaskTitle(e.target.value)}
              type="text"
              id="first_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
              placeholder="John"
              required
            />
          </div>
          <textarea
            onChange={(e) => settastDescription(e.target.value)}
            id="message"
            rows="4"
            className="block p-2.5  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
            placeholder="Write your thoughts here..."
          ></textarea>
          <button
            type="button"
            onClick={() => addItem()}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 "
          >
            Default
          </button>
        </div>
      </Dialog>
    </div>
  );
}
