/**
 *
 * @param {useState} setUserRegister
 * checks for all register values
 */

export async function controlToRegisterValues(setUserRegister) {
  await setUserRegister((userRegister) => ({
    ...userRegister,
    email: {
      value: !controlToEmail(userRegister.email.value)
        ? userRegister.email.value
        : "",
      error: controlToEmail(userRegister.email.value),
    },
    corporation: {
      value:
        userRegister.corporation.value.length > 5
          ? userRegister.corporation.value
          : "",
      error: userRegister.corporation.value.length > 5 ? false : true,
    },
    name: {
      value: userRegister.name.value.length > 1 ? userRegister.name.value : "",
      error: userRegister.name.value.length > 1 ? false : true,
    },
    surname: {
      value:
        userRegister.surname.value.length > 1 ? userRegister.surname.value : "",
      error: userRegister.surname.value.length > 1 ? false : true,
    },
    cellPhone: {
      value:
        userRegister.cellPhone.value.length > 5
          ? userRegister.cellPhone.value
          : "",
      error: userRegister.cellPhone.value.length > 5 ? false : true,
    },
    password: {
      value:
        userRegister.password.value.length > 5
          ? userRegister.password.value
          : "",
      error: userRegister.password.value.length > 5 ? false : true,
    },
    passwordRequret: {
      value:
        userRegister.passwordRequret.value.length > 5
          ? userRegister.passwordRequret.value
          : "",
      error: userRegister.passwordRequret.value.length > 5 ? false : true,
    },
    kvkk: {
      value: userRegister.kvkk.value,
      error: userRegister.kvkk.value === true ? false : true,
    },
  }));
}

function controlToEmail(email) {
  return !String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export async function controlToErrorStates(allUserData) {
  Object.keys(allUserData).map(function (_) {
    return allUserData[_];
  });
  return Object.values(allUserData).some((item) => true === item.error);
}
