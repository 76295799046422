import React from "react";
import Button from "../../../../components/Button/Button";
import Stepper from "../../../../components/Stepper/MainStepper";
import Threatening from "../../../../icons/threatening.svg";

import One from "../../../../icons/numbers/1.svg";
import Two from "../../../../icons/numbers/2.svg";
import Three from "../../../../icons/numbers/3.svg";
import Four from "../../../../icons/numbers/4.svg";
import Five from "../../../../icons/numbers/5.svg";
export default function CompetenceInformation({ changePage }) {
  //initial
  //services
  //states
  return (
    <div className="p-5 flex justify-center align-middle flex-col items-center text-left w-full">
      <div className="w-[1136px] text-black text-lg font-semibold font-['Outfit']">
        Değerli Firma Yetkilimiz,
      </div>

      <br />
      <div className="w-[1136px]">
        <span className="text-neutral-500 text-lg font-semibold font-['Outfit']">
          Kurumsal Girişim Pusulası
        </span>
        <span className="text-neutral-500 text-lg font-normal font-['Outfit']">
          {" "}
          firmaların kurum içi girişimcilik ve kurumsal inovasyon çalışmaların
          uçtan uca yönetebilmeleri için hazırlanmış rehber doküman ve araç
          setlerinden oluşmaktadır. <br /> <br />
          Kurum içi girişimcilik ve kurumsal inovasyon çalışmaları yarı
          yapılandırılmış sistem olarak bir dizi sırasıyla yürütülen
          faaliyetlerden oluşmaz. Bununla birlikte öncelikli olarak burada
          sizlere sunduğumuz İnovasyon Yetkinlik Değerlendirmesi ile başlayarak
          ve ardından İnovasyon Kültürünüzü ölçerek sürece başlamanızı öneririz.{" "}
          <br />
          <br />
          Öncelikli olarak bu modül ile mevcut inovasyon yetkinliğinizi ölçmeniz
          ve iyileşme alanlarınızı tespit etmeniz gerekmektedir. İnovasyon
          yetkinlik/olgunluk değerlendirmesi, bir firmanın inovasyon alanındaki
          durumunu anlamak, güçlü ve zayıf yönlerini belirlemek ve gelişim
          alanlarını tanımlamak için yapılan bir süreçtir. Bu değerlendirme
          genellikle belirli bir çerçeve veya model kullanılarak
          gerçekleştirilir ve organizasyonun inovasyon kapasitesini,
          stratejilerini, süreçlerini, kaynaklarını ve kültürünü değerlendirir.
          Kurumsal Girişim Pusulası ile de bu alanda sizlere bir anket sunarak
          öz değerlendirmenizi yapmanızı sağlıyoruz.{" "}
        </span>
        <br />
        <div className="w-[1117px] h-[69px] bg-orange-100 rounded-[11px] mt-5 flex items-center ">
          <img className="p-4" src={Threatening} />
          <div className="w-[961px] text-[#2977A0] text-lg font-semibold font-['Outfit']">
            İnovasyon Yetkinlik Anketi, her firma için bir kereliğine mahsus
            yönetici kademesinde doldurulması gereken bir ankettir.
            <br />
            Anket 5 temel boyuttan oluşmaktadır:
          </div>
        </div>
        <br />
        <div className="w-[1136px] flex">
          <img className="p-3" src={One} />
          <div>
            <span className="text-neutral-500 text-lg font-semibold font-['Outfit']">
              Strateji ve Liderlik:
            </span>

            <span className="text-neutral-500 text-lg font-normal font-['Outfit']">
              İnovasyonun kurumsal hedeflerle uyumunu, firma stratejileri
              içerisinde inovasyona yer verilip verilmediğini ve yönetimin
              inovasyona bakış açısını ölçer.{" "}
            </span>
          </div>
        </div>

        <div className="w-[1136px] flex">
          <img className="p-3" src={Two} />
          <div>
            {" "}
            <span className="text-neutral-500 text-lg font-semibold font-['Outfit']">
              Beceriler ve Yetenekler:
            </span>
            <span className="text-neutral-500 text-lg font-normal font-['Outfit']">
              {" "}
              İnovasyon için gereken beceri ve yeteneklerin olup olmadığını,
              doğru insan kaynaklarının inovasyon için ayrılıp ayrılmadığını ve
              bu ekibin inovasyon eğitimleri alıp almadığını ölçer.{" "}
            </span>
          </div>
        </div>
        <div className="w-[1136px] flex justify-center">
          <img className="p-3" src={Three} />
          <div>
            {" "}
            <span className="text-neutral-500 text-lg font-semibold font-['Outfit']">
              Araçlar ve Süreçler:
            </span>
            <span className="text-neutral-500 text-lg font-normal font-['Outfit']">
              {" "}
              İnovasyon fırsatlarını yakalamak için kurumsal bilgi hafızasının
              oluşup oluşmadığını, fikir/öneri toplama sisteminin kurulup
              kurulmadığını ve müşteriler de dahil olmak üzere dış paydaşlarla
              ne düzeyde bir etkileşim olduğunu ölçer.{" "}
            </span>
          </div>
        </div>
        <div className="w-[1136px] flex items-center">
          <img className="p-3" src={Four} />
          <div>
            <span className="text-neutral-500 text-lg font-semibold font-['Outfit']">
              Kaynaklar:{" "}
            </span>
            <span className="text-neutral-500 text-lg font-normal font-['Outfit']">
              İnovasyon için gereken finansal, fiziksel ve insan kaynaklarının
              etkin şekilde tahsis edilip edilmediğini ölçer.{" "}
            </span>
          </div>
        </div>
        <div className="w-[1136px] flex items-center">
          <img className="p-3" src={Five} />
          <div>
            {" "}
            <span className="text-neutral-500 text-lg font-semibold font-['Outfit']">
              Eylem/Yürütme:
            </span>
            <span className="text-neutral-500 text-lg font-normal font-['Outfit']">
              {" "}
              İnovasyon faaliyetlerinin ne kadar bir etki ve sonuca dönüştüğünü
              ölçer.{" "}
            </span>
          </div>
        </div>
        <br />
        <div className="w-[1136px] text-neutral-500 text-lg font-normal font-['Outfit']">
          Anketi doldurarak bu beş boyuttaki skorunuzu 1-5 arasında
          görebilirsiniz. Ayrıca zaman içerisinde kat ettiğiniz gelişmelere
          bağlı olarak anketi tekrar tekrar gözden geçirmenizi öneririz.{" "}
        </div>
      </div>
      <div className="w-full flex items-center ">
        <div className="w-[600px] flex-1">
          <div className="w-full pr-10 ">
            <Stepper nextChange={changePage} activeStep={1} stepLimit={3} />
          </div>
        </div>
      </div>
    </div>
  );
}
