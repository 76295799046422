import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import RightIcon from "../../icons/arrow-right.svg";
import RegisterIcon from "../../icons/register.svg";
import Logo from "../../images/GP-Logo.png";
import { useResetPasswordEmailMutation } from "../../services/ResetPasswordService";
import { errorService } from "../../services/error";

export default function ResetPasswordMail() {
  //initial
  const navigate = useNavigate();
  //services
  const [resetPasswordEmail, { isSuccess, error, data, isLoading }] =
    useResetPasswordEmailMutation();
  //states
  const [userEmail, setUserEmail] = useState();

  async function resetEmailUser() {
    const res = await resetPasswordEmail({
      email: userEmail,
    });
  }

  useEffect(() => {
    console.log("error", error);
    if (error?.originalStatus === 201) {
      toast.success(error.data);
    } else {
      errorService(error, navigate);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess === true) {
      toast.success(
        "Şifre degiştirme e-postanız gönderildi.Mailden linke tıklayarak değiştirebilirsiniz."
      );
    }
  }, [isSuccess]);

  return (
    <div className=" bg-[#F6F7FA] flex flex-col justify-center items-center w-full h-screen ">
      <div className="-mt-[8%]">
        <div className="flex items-center justify-center ">
          <div className="flex-1">
            <img className="h-[77px] w-[198px] mb-[44px]" src={Logo}></img>
          </div>
          <div className="h-[60px] mb-10">
            <Button
              iconLeft={RegisterIcon}
              className={"py-[16px] px-[35px]"}
              text={"Giriş yap"}
              onClick={() => navigate("/giris")}
            />
          </div>

          <div></div>
        </div>
        <div className="bg-white w-[600px] h-[382px] p-[40px] rounded-[8px] ">
          <form className="space-y-6" action="#" method="POST">
            <div className="text-black text-5xl font-medium font-['Outfit']">
              Şifremi Unuttum
            </div>
            <div className="w-[720px] text-gray-500 text-base font-normal font-['Outfit']">
              Lütfen e-posta adresinizi giriniz
            </div>

            <div className="flex">
              <div className="flex-1 mr-[8px]">
                <Input
                  name={"e-Posta"}
                  placeholder={"e-Posta adresi giriniz"}
                  labelName={"e-Posta"}
                  type={"email"}
                  autocomplete="email"
                  required={true}
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="flex items-center justify-between w-full">
              <div className="flex w-full items-start ml-0.5">
                <div className="flex-1 flex items-center "></div>
                <div className=" flex justify-end items-end  ">
                  <Button
                    className={"py-[16px] px-[35px]"}
                    text={"Onayla"}
                    iconRight={RightIcon}
                    disabled={isLoading}
                    onClick={() => resetEmailUser()}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
