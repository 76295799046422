import { toast } from "react-toastify";

export function errorService(error, navigate) {
  if (!!error) {
    if (error.status === 401) {
      navigate("/giris");
      return true;
    }

    if (error?.status === 0) {
      toast.error("Beklenmedik bir hata oldu.");
    } else {
      if (error?.data?.message) {
        toast.error(
          <div className="font-['Outfit']">{error?.data.message}</div>
        );
      } else {
        toast.error("Beklenmedik bir hata oldu.");
      }
    }
    return false;
  }
}
