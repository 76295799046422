import React from "react";

import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { MENU_ITEMS } from "../../components/Sidebar/constants";
import Lock from "../../icons/lock.svg";
import { useSelector } from "react-redux";

export default function Home() {
  const navigate = useNavigate();
  const menuItem = useSelector((state) => state.menu.menuItem);
  function MainButtons({ item }) {
    return (
      <div
        onClick={() =>
          navigate(
            item.subId
              ? item.navigation + "?subId=" + item.subId
              : item.navigation
          )
        }
        className="hover:border-[#439DC6] 
         hover:bg-[#d8eaf4] cursor-pointer hover:text-[#439DC6] h-[58px] bg-white rounded-[10px] border-2 border-neutral-200 flex  px-2 text-neutral-400"
      >
        <div className=" text-center   text-[11px] font-semibold font-['Outfit'] flex items-center justify-center justify-items-center w-full">
          <img className=" h-6 m-2 " src={item.srcHome} />
          <div className="  w-full h-full items-center flex justify-center">
            {item.title}{" "}
          </div>
        </div>
      </div>
    );
  }

  function MainCards({ menuItem }) {
    return (
      <>
        <div className="h-full bg-white rounded-[10px] border border-neutral-200 ">
          <div className="flex p-[23px] relative">
            <div className="flex flex-1 items-center justify-start relative">
              <div className="h-14 absolute -right-2 -top-2  rounded-full border-2 border-t-[#439DC6]">
                <img
                  className="fill-slate-600 h-6 m-4"
                  src={menuItem.srcHome}
                />
              </div>

              <div className="h-[46px] text-[#439DC6] text-xl font-medium font-['Outfit'] underline underline-offset-4">
                {menuItem.title}
              </div>
            </div>
          </div>
          <div className="w-full  text-neutral-400 text-sm font-normal font-['Outfit'] px-[23px] pb-4 ">
            {menuItem.desc}
          </div>
          <div className={`w-full h-[0px] border border-neutral-200  `}></div>
          <div className="relative">
            {!menuItem.lock && (
              <img
                onClick={() =>
                  window.open(
                    "https://kurumsalgirisimpusulasi.com/satin-al/",
                    "_blank"
                  )
                }
                className="h-16 hover:opacity-75 absolute z-10 cursor-pointer  right-0 left-[42%] top-14 bottom-0 items-center justify-center flex"
                src={Lock}
              />
            )}
            <div
              className={`w-full   text-neutral-400 text-sm font-normal font-['Outfit']  p-[23px] px-[23px] pb-4 ${
                !menuItem.lock && "blur-sm pointer-events-none"
              }`}
            >
              <div>
                <Grid container spacing={2}>
                  {menuItem?.subMenu?.map((item) => (
                    <Grid item xs={6}>
                      <MainButtons item={item} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="bg-[#F6F7FA]">
      <Header />
      <div className="flex items-center justify-center">
        <div className="py-5  w-[1300px]">
          <Grid container spacing={4}>
            {JSON.parse(JSON.stringify(menuItem)).map((item) => (
              <>
                {item.id !== 0 && (
                  <Grid item xs={4}>
                    <MainCards menuItem={item} />
                  </Grid>
                )}
              </>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}
