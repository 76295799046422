import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import downloadjs from "downloadjs";
import moment from "moment";
import autoTable from "jspdf-autotable";
import "moment/locale/tr";
import "../fonts/Roboto-Medium-normal.js";
import Logo from "../images/GP-Logo.png";

moment().locale("tr");

export const COLOR_SET_DEFAULT = [
  "#f8522e",
  "#f8893a",
  "#f7c045",
  "#abc32f",
  "#5ec618",
  "#368d6d",
  "#0d54c1",
  "#4048aa",
  "#733c93",
];

export function dowloandPDF(elementId, docName) {
  const canvas = document.getElementById(elementId);
  html2canvas(canvas).then((canvas) => {
    const image = canvas.toDataURL("image/png", 2);
    const doc = new jsPDF("p", "mm", "a4");
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = 295;

    const widthRatio = pageWidth / canvas.width;
    const heightRatio = pageHeight / canvas.height;
    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    const canvasWidth = canvas.width * ratio;
    const canvasHeight = canvas.height * ratio;

    // const marginX = (pageWidth - canvasWidth) / 2;
    const marginY = 40;

    //Footer Text

    var imgWidth = 210;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    var heightLeft = imgHeight;
    var position = 10; // give some top padding to first page

    doc.addImage(image, "PNG", 10, position, imgWidth - 20, imgHeight - 10);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight; // top padding for other pages
      doc.addPage();
      doc.addImage(image, "PNG", 10, position, imgWidth - 20, imgHeight - 10);
      heightLeft -= pageHeight;
    }

    // let strFooter = moment().format("MMMM Do YYYY, HH:mm:ss ");
    // doc.setTextColor(100);
    // doc.setFontSize(10);
    // doc.text(strFooter, pageWidth / 2, pageHeight - 8, { align: "center" });

    // //Header Text
    // let strHeader = "Business Canvas";
    // doc.setTextColor(50);
    // doc.setFontSize(15);
    // doc.text(strHeader, pageWidth / 2 - 5, 20, { align: "center" });

    //Canvas
    //doc.addImage(image, "JPEG", 10, marginY, canvasWidth - 20, canvasHeight);
    doc.save(docName + ".pdf");
  });
}

export function dowloandImage(elementId, docName) {
  const canvas = document.getElementById(elementId);
  html2canvas(canvas).then((canvas) => {
    const image = canvas.toDataURL("image/jpeg", 1.0);
    downloadjs(image, "download.png", "image/png");
  });
}

export async function dowloandPDFTable(array, graficId, title) {
  const doc = new jsPDF();
  doc.setFontSize(20);
  const pageWidth = doc.internal.pageSize.getWidth();
  doc.setFont("Roboto-Medium", "normal");

  let strFooter = moment().format("MMMM Do YYYY, HH:mm:ss");
  doc.setTextColor(100);
  doc.setFontSize(8);
  doc.text(strFooter, pageWidth - 14, 8, {
    align: "right",
  });
  doc.setTextColor(50);
  doc.setFontSize(8);
  doc.text("Fatih Arclk A.Ş", pageWidth - 14, 12, {
    align: "right",
  });

  doc.addImage(Logo, "PNG", 14, 5, 25, 0, undefined, false);

  array.forEach((element, index) => {
    autoTable(doc, { html: "#my-table" });
    const data = element.questions.map((item) => {
      switch (item.userAnswer) {
        case "Kesinlikle katılmıyorum":
          return [item.question, "X", "", "", "", ""];

        case "Kısmen katılıyorum":
          return [item.question, "", "X", "", "", ""];

        case "Belirli ölçüde katılıyorum":
          return [item.question, "", "", "X", "", ""];

        case "Büyük ölçüde katılıyorum":
          return [item.question, "", "", "", "X", ""];

        case "Tamamen katılıyorum":
          return [item.question, "", "", "", "", "X"];

        default:
          return [item.question, ""];
      }
    });
    autoTable(doc, {
      head: [
        [
          element.title + " - Sorular",
          "Kesinlikle katılmıyorum",
          "Kısmen katılıyorum",
          "Belirli ölçüde katılıyorum",
          "Büyük ölçüde katılıyorum",
          "Tamamen katılıyorum ",
        ],
      ],
      body: data,
      theme: "grid",
      headStyles: {
        fillColor: COLOR_SET_DEFAULT[index],

        halign: "center",
        valign: "middle",
        lineColor: "white",
      },

      columnStyles: {
        0: { cellWidth: 90, fontSize: 7 },
        1: { halign: "center", valign: "middle" },
        2: { halign: "center", valign: "middle" },
        3: { halign: "center", valign: "middle" },
        4: { halign: "center", valign: "middle" },
        5: { halign: "center", valign: "middle" },
      }, // Cells in first column centered and green
      styles: { fontSize: 7, font: "Roboto-Medium", fontStyle: "normal" },
    });
  });

  if (graficId) {
    const canvas = document.getElementById(graficId);
    var img;
    await html2canvas(canvas).then((canvas) => {
      img = canvas.toDataURL("image/png", 2);
    });

    autoTable(doc, { html: "#my-table" });
    autoTable(doc, {
      head: [["Grafik"]],
      bodyStyles: { minCellHeight: 100 },
      //body: [doc.addImage(img, "PNG", 20, 90, 180, 100)],
      theme: "plain",
      didDrawPage: (HookData) => {
        console.log("HookData: ", HookData);
        HookData.table.body = [
          doc.addImage(img, "PNG", 20, HookData.cursor.y, 180, 100),
        ];
      },
    });
  }

  doc.save(title);
}
