import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import LoadingFullPage from "../../../../components/LoadingFullPage/LoadingFullPage";
import Modal from "../../../../components/Modal/Modal";
import Stepper from "../../../../components/Stepper/MainStepper";
import AddIcon from "../../../../icons/inovation.svg";
import {
  subModuleCreateNewQuestions,
  subModuleDeleteQuestion,
  subModuleQuestions,
  subModuleSurveyAnswers,
} from "../../../../services/subModuleServices";
import RadioSurvey from "../Competence/RadioSurvey";

export default function CultureSurvey({
  changePage,
  backPage,
  subId,
  pageState,
}) {
  //initial
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //services

  //states
  const [inovationQuestions, setInovationQuestions] = useState(null);
  const [disableState, setdisableState] = useState(true);
  const [addQuestion, setaddQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  async function fetchQuestions() {
    setIsLoading(true);
    const questionsResponse = await subModuleQuestions(subId);
    setInovationQuestions(questionsResponse.data?.headers);
    setdisableState(
      await controlToValidateStates(questionsResponse.data?.headers)
    );
    setIsLoading(false);
  }

  useEffect(() => {
    fetchQuestions();
  }, []);

  const handleClickOpen = (title) => {
    setaddQuestion("");
    setSelectedValue(title);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  async function controlToValidateStates(questions) {
    var allQuestions = [];
    questions.forEach((element) => {
      Object.keys(element.questions).map(function (_) {
        allQuestions.push(element.questions[_]);
        return element.questions[_];
      });
    });

    return Object.values(allQuestions).some(
      (item) => undefined === item.userAnswer
    );
  }

  async function addNewQuestion(id) {
    const res = await subModuleCreateNewQuestions(subId, {
      header: selectedValue,
      text: addQuestion,
    });
    if (res?.status === 201) {
      fetchQuestions();
      handleClose();
    } else {
      toast.error("Soru eklemede hata oluştu");
    }
  }

  async function selectQuetions(selected, id, mainId) {
    inovationQuestions.forEach((element, index) => {
      if (element.id === mainId) {
        let newInovationQuestions = [...inovationQuestions];

        newInovationQuestions[index].questions.forEach((element) => {
          if (element.id === id) {
            element.userAnswer = selected;
          }
        });
        setInovationQuestions([...newInovationQuestions]);
      }
    });
    setdisableState(await controlToValidateStates(inovationQuestions));
  }

  async function nextInformation() {
    const answers = [];
    inovationQuestions.forEach((questions) => {
      questions.questions.forEach((question) => {
        if (question.userAnswer) {
          answers.push({
            questionId: question.id,
            answer: question.userAnswer,
          });
        }
      });
    });
    const response = await subModuleSurveyAnswers(subId, { answers: answers });
    if (response?.status === 204) {
      changePage();
    }
  }
  // Delete Questions
  async function deleteQuestions(defaultQuestion, id) {
    if (defaultQuestion) {
      return true;
    }
    const response = await subModuleDeleteQuestion(subId, id);
    if (response?.status === 204) {
      fetchQuestions();
    } else {
      toast.error("Silme işleminde bir hata oldu.");
    }
  }

  return (
    <div>
      <LoadingFullPage state={isLoading}>
        {!!inovationQuestions &&
          inovationQuestions.map((item) => (
            <div className="my-2">
              <div className="text-zinc-800 text-xl font-bold font-['Outfit'] flex items-center">
                <div className="flex-1">{item.title}</div>
              </div>
              <div>
                {item.questions.map((question, index) => (
                  <RadioSurvey
                    selectQuetions={(selected, id) =>
                      selectQuetions(selected, id, item.id)
                    }
                    question={question.question}
                    value={question.userAnswer}
                    id={question.id}
                    index={index}
                    defaultQuestion={question.isDefaultQuestion}
                    subId={subId}
                    deleteQuestions={() =>
                      deleteQuestions(question.isDefaultQuestion, question.id)
                    }
                  />
                ))}
              </div>
              <div className="w-full items-end flex justify-end">
                <button
                  className="rounded-md py-[9px] border font-['Outfit'] text-sm font-medium mb-2 mt-2 px-6 hover:bg-neutral-100"
                  onClick={() => handleClickOpen(item.title)}
                  src={AddIcon}
                >
                  Soru Ekle
                </button>
              </div>
            </div>
          ))}
        <div className="w-full flex items-center justify-center mt-6 ">
          <div className="w-[600px] flex-1">
            <div className="w-full pr-10 ">
              <Stepper
                nextDisable={disableState}
                nextChange={() => nextInformation()}
                backChange={backPage}
                activeStep={2}
                stepLimit={3}
                nextTooltip={
                  disableState &&
                  "Lütfen tüm soruları doldurunuz. Tüm sorular doldurulduktan sonra devam edebilirsiniz."
                }
              />
            </div>
          </div>

          <Modal
            header={true}
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
            title={"Soru Ekle"}
            desc="Lütfen soru eklemek için alt tarafı doldurunuz."
            children={
              <div className="p-1 flex justify-center items-center">
                <div className="w-[1200px] flex-1">
                  <Input
                    value={addQuestion}
                    onChange={(e) => setaddQuestion(e.target.value)}
                  />
                </div>
                <div className="ml-4 mt-2">
                  <Button text="Soru Ekle" onClick={() => addNewQuestion()} />
                </div>
              </div>
            }
          />
        </div>
      </LoadingFullPage>
    </div>
  );
}
