import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import { useAccountConfirmMutation } from "../../services/AccountConfirmService";
import { useAccountConfirmResetTokenMutation } from "../../services/AccountConfirmResetToken";
import { toast } from "react-toastify";
import Logo from "../../images/GP-Logo.png";
import Success from "../../images/Success.svg";
import Cancel from "../../images/Cancel.svg";
import { errorService } from "../../services/error";
import RightIcon from "../../icons/arrow-right.svg";

export default function AccountConfirm() {
  //initial
  const navigate = useNavigate();
  //services

  const [accountConfirmResetToken] = useAccountConfirmResetTokenMutation({});
  const [accountConfirm, { isSuccess, error }] = useAccountConfirmMutation({});
  //states
  const [searchParams, setSearchParams] = useSearchParams();

  async function confirmAccountReset(params) {
    const res = await accountConfirmResetToken(searchParams.get("t"));
    console.log("res: ", res);
  }

  async function confirmAccount(params) {
    const res = await accountConfirm({ token: searchParams.get("t") });
  }

  useEffect(() => {
    if (isSuccess === true) {
      toast.success("Başarılı bir şekilde mail adresin onaylandı.");
      setTimeout(() => {
        navigate("/anasayfa");
      }, 3000);
    }
  }, [isSuccess]);

  useEffect(() => {
    errorService(error, navigate);
  }, [error]);

  useEffect(() => {
    confirmAccount();
  }, []);

  return (
    <div className="bg-[#F6F7FA] flex flex-col justify-center items-center w-full h-screen ">
      <div className="flex items-center justify-center">
        <div className="flex-1">
          <img className="h-[77px] w-[198px] mb-[44px]" src={Logo}></img>
        </div>

        <div></div>
      </div>
      <div className=" w-[800px]   bg-white rounded-[14px] flex items-center justify-center flex-col p-[40px]">
        <div className="flex text-left w-full">
          <div className="text-black text-left text-5xl font-medium font-['Outfit']">
            {isSuccess ? "e-Posta Onay" : "e-Posta Onaylanamadı"}
          </div>
        </div>
        {isSuccess ? (
          <>
            <img className="mt-[30px]" src={Success}></img>
            <div className="w-[357px] text-center mt-[23px]">
              <span className="text-gray-500 text-xl font-normal font-['Outfit']">
                e-mail adresinize üzerinden üyeliğiniz onaylanmıştır.
              </span>
            </div>
            <div className="mt-10 flex items-center justify-end w-full">
              <Button
                iconRight={RightIcon}
                className={"p-[15px] px-[30px]"}
                onClick={() => navigate("/anasayfa")}
                text={"Giriş Yap"}
              />
            </div>
          </>
        ) : (
          <>
            <img className="mt-[30px]" src={Cancel}></img>
            <div className="w-[357px] text-center mt-[23px]">
              <div className="text-gray-500 text-xl font-normal font-['Outfit']">
                e-mail adresinize üzerinden üyeliğiniz onaylanamadı.{" "}
                <div
                  onClick={() => confirmAccountReset()}
                  className="inline underline cursor-pointer"
                >
                  Terkrar mail gönermek için tıklayın.
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
