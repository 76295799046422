import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { useLoginMutation } from "../../services/LoginService";
import Logo from "../../images/GP-Logo.png";
import RightIcon from "../../icons/arrow-right.svg";
import RegisterIcon from "../../icons/register.svg";
import { errorService } from "../../services/error";
import { toast } from "react-toastify";
import { useAccountConfirmResetMutation } from "../../services/AccountConfirmReset";
import { MENU_ITEMS } from "../../components/Sidebar/constants";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useDispatch } from "react-redux";
import { setSpinner } from "../../redux/slices/spinnerSlice";

export default function Login() {
  //initial
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //services
  const [login, { isSuccess, error, data, isLoading }] = useLoginMutation({});
  //states
  const [userLogin, setUserLogin] = useState({
    mail: "",
    password: "",
  });
  const [accountConfirmReset] = useAccountConfirmResetMutation({});
  const [rememberLogin, setRememberLogin] = useState(false);

  async function loginUser() {
    if (rememberLogin) {
      localStorage.setItem("mail", userLogin.mail);
      localStorage.setItem("password", userLogin.password);
    } else {
      localStorage.setItem("mail", "");
      localStorage.setItem("password", "");
    }
    const res = await login({
      email: userLogin.mail,
      password: userLogin.password,
    });
  }

  useEffect(() => {
    errorService(error, navigate);
  }, [error]);

  async function confirmAccountReset(params) {
    const res = await accountConfirmReset();
    if (res?.error) {
      errorService(res?.error, navigate);
    } else {
      toast.success("Mail gönderimi başarıyla tamamlanmıştır.");
    }
  }

  useEffect(() => {
    if (isSuccess === true) {
      if (data?.isConfirmed) {
        localStorage.setItem("menu", JSON.stringify(MENU_ITEMS));
        dispatch(setSpinner(true));
        navigate("/anasayfa");
      } else {
        toast.success(
          <div>
            Lütfen e-mail adresinizi onaylayın. Eğer onaylama maili
            almadıysanız.
            <div
              onClick={() => confirmAccountReset()}
              className="inline underline cursor-pointer"
            >
              &nbsp;Terkrar mail gönermek için tıklayın.
            </div>
          </div>
        );
      }
    }
  }, [isSuccess]);

  function rememberLoginState(remember) {
    localStorage.setItem("remember", remember);
    setRememberLogin(remember);
  }

  useEffect(() => {
    var rememberState = localStorage.getItem("remember");
    setRememberLogin(rememberState === "false" ? false : true);
    if (localStorage.getItem("remember")) {
      setUserLogin({
        mail: localStorage.getItem("mail"),
        password: localStorage.getItem("password"),
      });
    }
  }, []);

  return (
    <LoadingSpinner state={isLoading}>
      <div className=" bg-[#F6F7FA] flex flex-col justify-center items-center w-full h-screen ">
        <div className="-mt-[8%]">
          <div className="flex items-center justify-center ">
            <div className="flex-1">
              <img className="h-[77px] w-[198px] mb-[44px]" src={Logo}></img>
            </div>
            <div className="h-[60px] mb-10">
              <Button
                iconLeft={RegisterIcon}
                className={"py-[16px] px-[35px]"}
                text={"Kayıt Ol"}
                onClick={() => navigate("/kayit")}
              />
            </div>

            <div></div>
          </div>
          <div className="bg-white w-[800px] h-[382px] p-[40px] rounded-[8px] ">
            <form className="space-y-6" action="#" method="POST">
              <div className="text-black text-5xl font-medium font-['Outfit']">
                Giriş Yap
              </div>
              <div className="w-[720px] text-gray-500 text-base font-normal font-['Outfit']">
                Girişim Pusulası ile becerilerini değerlendir
              </div>

              <div className="flex">
                <div className="flex-1 mr-[8px]">
                  <Input
                    name={"e-Posta"}
                    placeholder={"e-Posta adresiniz giriniz"}
                    labelName={"e-Posta"}
                    type={"email"}
                    autocomplete="e-mail"
                    required={true}
                    value={userLogin.mail}
                    onChange={(e) =>
                      setUserLogin({ ...userLogin, mail: e.target.value })
                    }
                  />
                </div>

                <div className="flex-1 ml-[8px]">
                  <Input
                    name={"Şifre"}
                    placeholder={"**************"}
                    labelName={"Şifre"}
                    type={"password"}
                    autocomplete="current-password"
                    required={true}
                    value={userLogin.password}
                    onChange={(e) =>
                      setUserLogin({ ...userLogin, password: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="flex items-center justify-between w-full">
                <div className="flex w-full items-start ml-0.5">
                  <div className="flex-1 flex items-center ">
                    <div className="flex-1">
                      {/* <Chechbox
                        text={" Giriş bilgilerini hatırla"}
                        value={rememberLogin}
                        onChange={(e) => rememberLoginState(e.target.checked)}
                      /> */}
                    </div>

                    <div
                      onClick={() => navigate("/sifremi-unuttum")}
                      className="flex mr-[92px] text-gray-500 text-base font-normal font-['Outfit'] cursor-pointer hover:text-gray-400"
                    >
                      Şifremi Unuttum
                    </div>
                  </div>
                  <div className=" flex justify-end items-end  ">
                    <Button
                      className={"py-[16px] px-[35px]"}
                      text={"Onayla"}
                      iconRight={RightIcon}
                      onClick={() => loginUser()}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LoadingSpinner>
  );
}
