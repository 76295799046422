import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";

import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { Dialog } from "@mui/material";
import CloseModal from "../../icons/close-modal.svg";

const emails = ["username@gmail.com", "user02@gmail.com"];

export default function Modal({
  onClose,
  selectedValue,
  open,
  title,
  desc,
  children,
  header,
}) {
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <div className="p-4 min-w-[471px]  bg-white rounded-[20px] border border-neutral-200 relative">
        <div className="w-full  relative">
          <img
            onClick={handleClose}
            className="absolute hover:opacity-75 cursor-pointer right-0 "
            src={CloseModal}
          />
        </div>
        {header && (
          <>
            <div className="text-slate-900   text-2xl font-medium font-['Outfit'] whitespace-nowrap text-ellipsis">
              {title}
            </div>
            <div className="text-stone-400 0 text-base font-normal font-['Outfit'] whitespace-nowrap text-ellipsis">
              {desc}
            </div>
          </>
        )}
        {children}
      </div>
    </Dialog>
  );
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
