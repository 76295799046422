import Header from "../../components/Header/Header";
import Business from "../../images/business.svg";
import { useAccountInformationQuery } from "../../services/AccountInformation";
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import Users from "./Users/Users";
import ChangePassword from "./ChangePassword/ChangePassword";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Profile() {
  const { isSuccess, error, data, isLoading } = useAccountInformationQuery();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="bg-[#F6F7FA] w-screen h-screen p-10 flex items-center flex-col">
      <Header />
      <div className="w-[1640px] h-[648px] bg-white rounded-[10px] border border-neutral-200 mt-4 px-[126px] py-[56px] flex">
        <div className="flex flex-col items-center ">
          <div className="w-[216px] h-[216px] bg-[#439DC6] rounded-[20px] flex p-4">
            <img src={Business} />
          </div>
          <div className="mt-5">
            <div>
              <div className="text-zinc-800 text-3xl font-bold font-['Outfit']">
                Fatih Arıcılık
              </div>
              <div className="text-zinc-800 text-xl font-light font-['Outfit']">
                Programmer
              </div>
            </div>
          </div>
        </div>
        <div className="ml-8  w-full">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: "#439DC6",
                    color: "#439DC6",
                    textTransform: "none",
                  },
                  color: "#439DC6",
                }}
              >
                <Tab
                  sx={{ textTransform: "none" }}
                  label="İletişim Bilgilerim"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{ textTransform: "none" }}
                  label="Kullanıcılar"
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{ textTransform: "none" }}
                  label="Şifremi Güncelle"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <PersonalInformation />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Users />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <ChangePassword />
            </CustomTabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
}
