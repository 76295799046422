import MobileStepper from "@mui/material/MobileStepper";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import Button from "../Button/Button";
import RightIcon from "../../icons/arrow-right.svg";
import ArrowLeftIcon from "../../icons/arrow-left-gray.svg";
import { GREY_TYPE } from "../Button/constants";

export default function Stepper({
  activeStep,
  stepLimit,
  backChange,
  nextChange,
  backTitle,
  nextTitle,
  backDisable,
  nextDisable,
  nextTooltip,
  rightIcon,
}) {
  return (
    <div className="flex items-center justify-center mt-5">
      <div className="flex mr-[35px]">
        <div className="text-[#439DC6] text-lg font-semibold font-['Outfit']">
          {activeStep}
        </div>
        <div className="text-zinc-400 text-lg font-light font-['Outfit']">
          /
        </div>
        <div className="text-zinc-400 text-lg font-light font-['Outfit']">
          {stepLimit}
        </div>
      </div>
      {activeStep !== 1 && (
        <div className=" mr-8  w-48 flex">
          <Button
            className=" w-[140px]"
            onClick={backChange}
            text={backTitle ? backTitle : "Geri"}
            iconLeft={ArrowLeftIcon}
            iconType={GREY_TYPE}
            disabled={backDisable}
          />
        </div>
      )}

      <ol class="flex items-center w-full">
        {[...Array(activeStep)].map((x, i) => (
          <li class="flex w-full items-center text-blue-600 dark:text-blue-500 after:content-[''] after:w-full after:h-1 after:border-b after:border-[#439DC6] after:border-4 after:inline-block " />
        ))}
        {[...Array(stepLimit - activeStep)].map((x, i) => (
          <li class="flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block " />
        ))}
      </ol>
      <div className=" ml-8  w-48 flex">
        <Button
          tooltip={nextTooltip}
          className=" w-[140px]"
          onClick={nextChange}
          text={nextTitle ? nextTitle : "Devam"}
          iconRight={rightIcon ? rightIcon : RightIcon}
          disabled={nextDisable}
        />
      </div>
    </div>
  );
}
