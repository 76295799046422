import React, { useEffect } from "react";
import ArrowRight from "../../icons/arrow-right-gray.svg";
import User from "../../icons/user.svg";
import Settings from "../../icons/settings.svg";
import { useNavigate } from "react-router-dom";
import { useAccountInformationQuery } from "../../services/AccountInformation";
import {
  useLogoutMutation,
  useLogoutQuery,
} from "../../services/LogoutService";
import { errorService } from "../../services/error";
import { Skeleton } from "@mui/material";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
export default function Header({ title, subTexts }) {
  //initial
  const navigate = useNavigate();
  //services
  const { isSuccess, error, data, isLoading } = useAccountInformationQuery();
  const [logout] = useLogoutMutation();

  useEffect(() => {
    errorService(error, navigate);
  }, [error]);

  //states

  async function logoutUser(params) {
    var data = await logout();
    navigate("/giris");
  }

  function StepHeader(title, subTexts) {
    return (
      <>
        <div className="flex flex-1 items-center ">
          <div className="text-zinc-800 text-2xl font-bold font-['Outfit'] whitespace-nowrap text-ellipsis">
            {title}
          </div>
          <div className="ml-7 flex items-center justify-center text-left ">
            {subTexts?.map((title, index) =>
              subTexts.length - 1 === index ? (
                <>
                  <div className="text-slate-900 text-base font-normal font-['Outfit'] whitespace-nowrap text-ellipsis ">
                    {title}
                  </div>
                </>
              ) : (
                <>
                  <div className="text-stone-300 0 text-base font-normal font-['Outfit'] whitespace-nowrap text-ellipsis ">
                    {title}
                  </div>
                  <img className="p-2 mt-0.5" src={ArrowRight}></img>
                </>
              )
            )}
          </div>
        </div>
      </>
    );
  }

  function WelcomeHeader() {
    return (
      <>
        <div className="flex flex-1 items-center ">
          <div>
            <span className="text-gray-500 text-3xl font-light font-['Outfit']">
              Hoşgeldin
            </span>
            <span className="text-gray-500 text-3xl font-medium font-['Outfit']">
              {" "}
            </span>

            <span className="text-zinc-900 text-3xl font-extrabold font-['Outfit']">
              <> {data?.name}</>
            </span>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="flex mt-[23px] mb-[38px] w-full">
        {title ? StepHeader(title, subTexts) : WelcomeHeader()}

        <div>
          <div className="w-[313px] h-[70px] relative">
            <div className="w-[313px] h-[70px] left-0 top-0 absolute rounded-lg  border border-neutral-200" />
            <div className="w-[47px] h-[47px] left-[9px] top-[12px] absolute rounded-full border border-neutral-200" />
            <div className="w-6 h-6 left-[21px] top-[23px] absolute justify-center items-center inline-flex">
              <div className="w-6 h-6 relative">
                <img src={User} />
              </div>
            </div>
            <div className="w-[169px] h-[42px] left-[70px] top-[15px] absolute">
              <div className="left-0 top-0 absolute text-gray-900 text-lg font-bold font-['Outfit'] truncate w-full">
                {data?.name} {data?.surname}
              </div>
              <div className="left-0 top-[22px] absolute text-neutral-400 text-base font-medium font-['Outfit'] truncate w-full">
                {data?.corporation}
              </div>
            </div>
            <div className="w-6 h-6 left-[278px] top-[23px] absolute justify-center items-center inline-flex">
              <div className="w-6 h-6 relative">
                <img src={Settings} onClick={() => logoutUser()} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`" h-[0px] border border-neutral-200 w-full `}></div>
    </>
  );
}
