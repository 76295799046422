import React, { useEffect, useState } from "react";
import Header from "../../../../components/Header/Header";
import { useSearchParams } from "react-router-dom";
import Information from "./Information";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function DeterminingMarketDiversity() {
  //initial
  const location = useLocation();
  console.log(location.pathname);
  const menuItem = useSelector((state) => state.menu.menuItem);
  const [lock, setLock] = useState(false);

  useEffect(() => {
    menuItem.forEach((item) => {
      item.subMenu?.forEach((subMenu) => {
        if (subMenu.navigation === location.pathname) {
          console.log(item.lock);
          var lockState = item.lock;
          setLock(lockState);
        }
      });
    });
  }, []);

  //services
  //states
  const [pageState, setPageState] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  console.log("searchParams: ", searchParams);

  const [headersText, setHeadersText] = useState([
    {
      title: "Pazar Çekiciliği Belirlenmesi Modülü ",
      subTexts: ["Bilgilendirme"],
    },
    {
      title: "İnovasyon Yetkinlik Değerlendirmesi",
      subTexts: ["Bilgilendirme", "Anket Modülü"],
    },
    {
      title: "İnovasyon Yetkinlik Değerlendirmesi",
      subTexts: ["Bilgilendirme", "Anket Modülü", "Rapor Sonucu"],
    },
  ]);

  return (
    <>
      <Header
        title={headersText[pageState].title}
        subTexts={headersText[pageState].subTexts}
      />
      <div className="w-full flex items-center justify-center mt-[40px]">
        <div
          className="w-[1200px]  bg-white rounded-[10px] border border-neutral-200 mt-2 p-[32px]"
          id="surver-inovation"
        >
          {pageState === 0 && (
            <Information
              lock={lock}
              changePage={() =>
                lock
                  ? setPageState(1)
                  : window.open(
                      "https://kurumsalgirisimpusulasi.com/satin-al/",
                      "_blank"
                    )
              }
            />
          )}
          {/* {pageState === 1 && (
            <CompentenceSurvey
              subId={searchParams.get("subId")}
              backPage={() => setPageState(0)}
              changePage={() => setPageState(2)}
              pageState={pageState}
            />
          )}
          {pageState === 2 && (
            <CompetenceGraphic
              subId={searchParams.get("subId")}
              backPage={() => setPageState(1)}
            />
          )} */}
        </div>
      </div>
    </>
  );
}
