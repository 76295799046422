import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Canvas from "../components/Canvas/Canvas";
import DndTable from "../components/DnDTable/DndTable";
import Sidebar from "../components/Sidebar/Sidebar";
import AccountConfirm from "../pages/AccountConfirm/AccountConfirm";
import Competence from "../pages/Modules/InnovationCheckUp/Competence/Competence";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import Culture from "../pages/Modules/InnovationCheckUp/Culture/Culture";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import ResetPasswordMail from "../pages/ResetPassword/ResetPasswordMail";
import { useSelector } from "react-redux";
import Empathy from "../pages/Modules/CustomerAndProblemAnalysis/Empathy/Empathy";
import ProblemDefinition from "../pages/Modules/CustomerAndProblemAnalysis/ProblemDefinition/ProblemDefinition";
import IdeaGenerationAndEvaluation from "../pages/Modules/CustomerAndProblemAnalysis/IdeaGenerationAndEvaluation/IdeaGenerationAndEvaluation";
import DeterminingValueInnovation from "../pages/Modules/Intrapreneurship/DeterminingValueInnovation/DeterminingValueInnovation";
import DeterminingMarketDiversity from "../pages/Modules/Intrapreneurship/DeterminingMarketDiversity/DeterminingMarketDiversity";
import MarketCompatibilityTests from "../pages/Modules/Intrapreneurship/MarketCompatibilityTests/MarketCompatibilityTests";
import BusinessModelDesign from "../pages/Modules/Intrapreneurship/BusinessModelDesign/BusinessModelDesign";
import StakeholderAnalysis from "../pages/Modules/StrategicPlan/StakeholderAnalysis/StakeholderAnalysis";
import InternalEnvironmentAnalysis from "../pages/Modules/StrategicPlan/InternalEnvironmentAnalysis/InternalEnvironmentAnalysis";
import MarketAnalysis from "../pages/Modules/StrategicPlan/MarketAnalysis/MarketAnalysis";
import EnvironmentalAnalysis from "../pages/Modules/StrategicPlan/EnvironmentalAnalysis/EnvironmentalAnalysis";
import SwotAnalysis from "../pages/Modules/StrategicPlan/SwotAnalysis/SwotAnalysis";
import StrategicDirection from "../pages/Modules/StrategicPlan/StrategicDirection/StrategicDirection";
import ProjectPlanning from "../pages/Modules/StrategicPlan/ProjectPlanning/ProjectPlanning";
import DeterminingForeignTargetMarket from "../pages/Modules/MarketResearchAndExport/DeterminingForeignTargetMarket/DeterminingForeignTargetMarket";
import DeterminationOfExportPotential from "../pages/Modules/MarketResearchAndExport/DeterminationOfExportPotential/DeterminationOfExportPotential";
import DeterminationOfExportIntent from "../pages/Modules/MarketResearchAndExport/DeterminationOfExportIntent/DeterminationOfExportIntent";
import FindingInternationalCustomers from "../pages/Modules/MarketResearchAndExport/FindingInternationalCustomers/FindingInternationalCustomers";
import Profile from "../pages/Profile/Profile";

export default function Router() {
  const spinner = useSelector((state) => state.spinner.spinner);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Sidebar />}>
          {/*Innovation Check-up */}
          <Route path="/inavasyon-yetkinlik" element={<Competence />} />
          <Route path="/inavasyon-kültür" element={<Culture />} />
          {/*Customer And Problem Analysis */}
          <Route path="/empati" element={<Empathy />} />
          <Route path="/problem-tanimlama" element={<ProblemDefinition />} />
          <Route
            path="/fikir-üretme-değerlendirme"
            element={<IdeaGenerationAndEvaluation />}
          />
          {/*Intrapreneurship */}
          <Route
            path="/değer-inavasyonu-belirlenmesi"
            element={<DeterminingValueInnovation />}
          />
          <Route
            path="/pazar-cesitliligi-belirlenmesi"
            element={<DeterminingMarketDiversity />}
          />
          <Route
            path="/pazar-uyum-testleri"
            element={<MarketCompatibilityTests />}
          />
          <Route path="/is-modeli-tasarimi" element={<BusinessModelDesign />} />
          {/*Strategic Plan */}
          <Route path="/paydas-analizi" element={<StakeholderAnalysis />} />
          <Route
            path="/ic-cevre-analizi"
            element={<InternalEnvironmentAnalysis />}
          />
          <Route
            path="/ic-cevre-analizi"
            element={<InternalEnvironmentAnalysis />}
          />
          <Route path="/pazar-analizi" element={<MarketAnalysis />} />
          <Route path="/cevre-analizi" element={<EnvironmentalAnalysis />} />
          <Route path="/swot-analizi" element={<SwotAnalysis />} />
          <Route
            path="/stratejik-yonlendirme"
            element={<StrategicDirection />}
          />
          <Route path="/projelendirme" element={<ProjectPlanning />} />
          {/*Market Research And Export */}

          <Route
            path="/ihracat-niyetinin-belirlenmesi"
            element={<DeterminationOfExportIntent />}
          />
          <Route
            path="/ihracat-potansiyelinin-belirlenmesi"
            element={<DeterminationOfExportPotential />}
          />
          <Route
            path="/yurtdişi-hedef-pazar-belirlenmesi"
            element={<DeterminingForeignTargetMarket />}
          />
          <Route
            path="/yurtdisi-müsteri-bulma"
            element={<FindingInternationalCustomers />}
          />

          <Route exact path="/anasayfa" element={<Home />} />
        </Route>
        <Route></Route>
        <Route path="*" element={<Navigate to="/anasayfa" />} />
        <Route path="/kayit" element={<Register />} />
        <Route path="/profil" element={<Profile />} />
        <Route path="/confirm-account" element={<AccountConfirm />} />
        <Route path="/hesabimi-onayla" element={<AccountConfirm />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/parola-sifirla" element={<ResetPassword />} />
        <Route path="/sifremi-unuttum" element={<ResetPasswordMail />} />
        <Route path="/giris" element={<Login />} />
        <Route path="/kanvas" element={<Canvas />} />
        <Route path="/tablo" element={<DndTable />} />
      </Routes>
    </BrowserRouter>
  );
}
