import React from "react";
import { useLocation } from "react-router-dom";
import Logo from "../../images/GP-Logo.png";

export default function NotFound() {
  return (
    <div className="h-screen lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16 ">
      <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
        <div className="relative">
          <div className="absolute">
            <div className="">
              <h1 className="my-2 text-gray-800 font-bold text-2xl text-center font-['Outfit']"></h1>
              <p className="my-2 text-gray-800 text-center">
                Üzgünüz, şu an sitemiz mobil versiyonda çalışmamaktadır. Daha
                stabil bir deneyim için lütfen bilgisayardan devam ediniz.
              </p>
              <div className="mt-10 items-center flex justify-centerfont-['Outfit']"></div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div>
        <img className="px-16" src={Logo} />
      </div>
    </div>
  );
}
