import { BarChart } from "@mui/x-charts/BarChart";
import React, { useEffect, useState } from "react";
import Button from "../../../../components/Button/Button";
import LoadingFullPage from "../../../../components/LoadingFullPage/LoadingFullPage";
import Stepper from "../../../../components/Stepper/MainStepper";

import { dowloandPDFTable } from "../../../../utils/fileUtils";
import {
  subModuleQuestions,
  subModuleResult,
} from "../../../../services/subModuleServices";
import Download from "../../../../icons/download.svg";

export default function CompetenceGraphic({ backPage, subId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [inovationQuestions, setInovationQuestions] = useState(null);
  const [grapData, setGrapData] = useState([]);
  const stackArray = ["A", "B", "C", "D", "E", "F"];

  async function fetchResult() {
    setIsLoading(true);
    const resultResponse = await subModuleResult(subId);
    var datas = [];
    resultResponse.data?.scores.forEach((element, index) => {
      datas.push({
        data: [element.average],
        stack: stackArray[index],
        label: element.header,
      });
    });
    setGrapData(datas);
    setIsLoading(false);
  }

  async function fetchQuestions() {
    setIsLoading(true);
    const questionsResponse = await subModuleQuestions(subId);
    setInovationQuestions(questionsResponse.data?.headers);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchQuestions();
    fetchResult();
  }, []);

  return (
    <div className="w-full flex items-center ">
      <div className="w-[600px] flex-1">
        <LoadingFullPage state={isLoading}>
          <div className="w-full pr-10 ">
            <div className="flex">
              <div className="text-zinc-800 text-xl font-bold font-['Outfit'] flex-1">
                Kurumsal İnovasyon Yetkinlik Değerlendirmesi​
              </div>
              {/* <Button
                text={"PDF İNDİR"}
                onClick={() =>
                  dowloandPDFTable(
                    inovationQuestions,
                    "innovation-competence",
                    "İnovasyon Yetkinlik Değerlendirmesi.pdf"
                  )
                }
              /> */}
            </div>

            <div className="bg-white rounded-[10px] border border-neutral-200 mt-10">
              <div
                id="innovation-competence"
                className="flex items-center justify-center my-5"
              >
                <BarChart
                  series={grapData}
                  width={800}
                  height={450}
                  colors={[
                    "#f8522e",
                    "#f8893a",
                    "#f7c045",
                    "#abc32f",
                    "#5ec618",
                  ]}
                  xAxis={[
                    {
                      scaleType: "band",
                      data: ["İnavasyon Yetkinlik Anketi"],
                      categoryGapRatio: 0.1,
                      barGapRatio: 1.2,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="bg-white rounded-[10px] border border-neutral-200 mt-10 flex p-3">
              {grapData.map((element) => (
                <div className="flex flex-col items-center justify-center m-5 flex-1">
                  <div className="text-center text-zinc-800 text-sm font-semibold font-['Outfit'] underline underline-offset-4">
                    {element.label}
                  </div>
                  <div className="text-center text-zinc-800 text-xl mt-2 font-semibold font-['Outfit']">
                    {element.data[0]}
                  </div>
                </div>
              ))}
            </div>

            <Stepper
              nextTitle={"PDF"}
              backChange={backPage}
              nextChange={() =>
                dowloandPDFTable(
                  inovationQuestions,
                  "innovation-competence",
                  "İnovasyon Yetkinlik Değerlendirmesi.pdf"
                )
              }
              activeStep={3}
              stepLimit={3}
              rightIcon={Download}
            />
          </div>
        </LoadingFullPage>
      </div>
    </div>
  );
}
