export const LABEL_STYLE = {
  DEFAULT: "block text-[18px] font-medium font-['Outfit']  text-[#17191C] ",
  ERROR: "block text-[18px] font-medium font-['Outfit']  text-red-600 ",
};

export const INPUT_STYLE = {
  DEFAULT:
    "appearance-none font-['Outfit']  h-[50px] rounded-[8px] relative block w-full mt-[12px] p-[16px] border border-[#E0E0E0] placeholder-[#81898F] placeholder:text-[14px] placeholder:font-normal text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-[#439DC6] focus:z-10 sm:text-sm",
  ERROR:
    "border-[2px] appearance-none font-['Outfit']  h-[50px] rounded-[8px] relative block w-full mt-[12px] p-[16px] border border-red-600 placeholder-red-600 placeholder:text-[14px] placeholder:font-normal text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm",
};

export const NUMBER_REGEX = /^[0-9\b]+$/;
export const TEXT_REGEX =
  /^[a-zA-ZöçşığüÖÇŞİĞÜ,]+(\s{0,1}[a-zA-ZöçşığüÖÇŞİĞÜ, ])*$/;
