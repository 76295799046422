import React, { useState } from "react";
import {
  INPUT_STYLE,
  LABEL,
  LABEL_STYLE,
  NUMBER_REGEX,
  TEXT_REGEX,
} from "./constants";

import EyeOpen from "../../icons/eye-open.svg";
import EyeClose from "../../icons/eye-close.svg";
import { Tooltip } from "@mui/material";

export default function Input({
  required,
  placeholder,
  onChange,
  value,
  name,
  type,
  autocomplete,
  labelName,
  labelClassName,
  error,
  regexType,
  maxLength,
  tooltip,
  tooltipTitle,
  tooltipPosition,
}) {
  const [passwordIcon, setPasswordIcon] = useState(false);

  function label() {
    return (
      <label
        for="email"
        className={
          error
            ? LABEL_STYLE.ERROR + labelClassName
            : LABEL_STYLE.DEFAULT + labelClassName
        }
      >
        {labelName}
        {required && "*"}
      </label>
    );
  }

  function onChangeInput(e) {
    switch (regexType) {
      case "number":
        if (e.target.value === "" || NUMBER_REGEX.test(e.target.value)) {
          onChange(e);
        }
        break;
      case "text":
        if (e.target.value === "" || TEXT_REGEX.test(e.target.value)) {
          onChange(e);
        }
        break;
      default:
        onChange(e);
        break;
    }
  }

  return (
    <div>
      {labelName && label()}
      <div className="mt-1 relative">
        <Tooltip
          placement={tooltipPosition}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "white",
                "& .MuiTooltip-arrow": {
                  color: "white",
                },
                borderWidth: 1,
              },
            },
          }}
          title={tooltipTitle}
        >
          <input
            id={name}
            name={name}
            type={
              type === "password" ? (!passwordIcon ? "password" : "text") : type
            }
            onChange={(e) => onChangeInput(e)}
            value={value}
            maxLength={maxLength}
            autocomplete={autocomplete}
            required={required}
            className={error ? INPUT_STYLE.ERROR : INPUT_STYLE.DEFAULT}
            placeholder={placeholder}
          />
        </Tooltip>
        {type === "password" && (
          <div className="absolute top-[14px] right-3 cursor-pointer z-10">
            <img
              onClick={() => setPasswordIcon(!passwordIcon)}
              src={passwordIcon ? EyeOpen : EyeClose}
            />
          </div>
        )}
      </div>
    </div>
  );
}
