import React, { useEffect, useState } from "react";
import GridMenu from "../../icons/grid-4.svg";
import Logo from "../../images/GP-Logo.png";
import SmallLogo from "../../images/GP-Small-Logo.svg";

import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import ArrowDown from "../../icons/arrow-down.svg";
import CircleBlue from "../../icons/circle-blue.svg";
import Circle from "../../icons/circle.svg";
import Lock from "../../icons/lock.svg";
import { setMenu } from "../../redux/slices/menuSlice";
import { useAllModulesQuery } from "../../services/subModulesService";
import LoadingFullPage from "../LoadingFullPage/LoadingFullPage";

export default function Sidebar({ children }) {
  const [open, setOpen] = useState(true);
  const [subOpen, setSubOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const menuItem = useSelector((state) => state.menu.menuItem);

  const [Menus, setMenus] = useState(JSON.parse(JSON.stringify(menuItem)));
  const { isSuccess, error, data, isLoading } = useAllModulesQuery();

  function onClickMenuItem(id, path, lock) {
    let MenuArray = [...Menus];
    MenuArray.forEach((element) => {
      if (element.id === id) {
        element.focus = true;
      } else {
        element.focus = false;
      }
    });
    setMenus(MenuArray);
    setSubOpen(!subOpen);
    MenuArray.forEach((element) => {
      if (element.id === id) {
        element.open = !element.open;
      }
    });
    MenuArray.forEach((element) => {
      element.subMenu?.forEach((element) => {
        if (element.id !== id) {
          element.focus = false;
        }
      });
    });
    localStorage.setItem("menu", JSON.stringify(MenuArray));
    console.log(path);

    navigate(path);
  }

  // TODO Bu kısım patlere göre güncelenecek
  // useEffect(() => {
  //   localStorage.setItem("menu", JSON.stringify(MENU_ITEMS));
  // }, []);

  useEffect(() => {
    if (isSuccess) {
      let MenuArray = [...Menus];

      MenuArray.forEach((menu) => {
        JSON.parse(JSON.stringify(data)).forEach((element) => {
          if (element.title === menu.title) {
            menu.lock = element.isPurchased;
            if (element?.subModules) {
              element.subModules.forEach((element) => {
                menu?.subMenu.forEach((subMenu) => {
                  if (subMenu.title == element.title) {
                    subMenu.subId = element.id;
                  }
                });
              });
            }
          }
        });
      });
      dispatch(setMenu(JSON.parse(JSON.stringify(MenuArray))));

      localStorage.setItem("menu", JSON.stringify(MenuArray));
    }
  }, [isSuccess]);

  function onClickSub(id, menuId, path, subId, lock) {
    if (lock === false) {
      window.open("https://kurumsalgirisimpusulasi.com/satin-al/", "_blank");
      return true;
    }
    let MenuArray = [...Menus];
    MenuArray.forEach((element) => {
      if (element.id === menuId) {
        element.subMenu.forEach((element) => {
          if (element.id === id) {
            element.focus = true;
          } else {
            element.focus = false;
          }
        });
      }
    });

    MenuArray.forEach((element) => {
      if (element.id !== menuId) {
        element.focus = false;
      }
    });

    MenuArray.forEach((element) => {
      if (element.id === menuId) {
        element.focus = true;
      } else {
        element.focus = false;
      }
    });
    // dispatch(setMenu(MenuArray));
    setMenus(MenuArray);
    setSubOpen(!subOpen);

    localStorage.setItem("menu", JSON.stringify(MenuArray));

    subId ? navigate(path + "?subId=" + subId) : navigate(path);
  }

  useEffect(() => {
    const Menus = JSON.parse(localStorage.getItem("menu"));
    if (Menus) {
      setMenus(Menus);
    }
  }, []);

  return (
    <LoadingFullPage state={isLoading}>
      <div className="flex bg-[#F6F7FA] h-full min-h-screen">
        <div
          // onMouseEnter={() => setOpen(true)}
          // onMouseLeave={() => setOpen(false)}
          onClick={() => open === false && setOpen(true)}
          className={` ${
            open ? "w-[364px]" : "w-20 "
          } bg-dark-purple h-screen scroll  p-5  pt-8 pb-8  fixed duration-300 bg-white rounded-tr-[10px] rounded-br-[10px] mt-5 mb-5 `}
        >
          <img
            src={GridMenu}
            className={`absolute cursor-pointer right-5 top-9   ${
              !open && "w-0"
            }`}
            onClick={() => setOpen(!open)}
          />
          <div className="flex gap-x-4 items-center">
            <img
              src={open ? Logo : SmallLogo}
              className={`cursor-pointer w-[130px] duration-500 ${
                open && " transition-all"
              }`}
              onClick={() => navigate("/anasayfa")}
            />
          </div>
          <div className="py-11 my-[14px] h-full overflow-auto scrollbar-hide">
            {Menus.map((Menu, index) => (
              <>
                <div
                  onClick={() =>
                    onClickMenuItem(Menu.id, Menu.navigation, Menu.lock)
                  }
                  key={index}
                  className={`flex select-none  rounded-md p-2 cursor-pointer hover:bg-light-white my-[14px] py-[11px]  text-sm items-center gap-x-4 
          ${Menu.gap ? "mt-9" : "mt-2"} ${index === 0 && "bg-light-white"} ${
                    Menu.focus === true
                      ? "bg-[#D9EBF4] text-[#439DC6]"
                      : "text-[#929398]"
                  }  ${
                    Menu.disable === true
                      ? "bg-[#D9EBF4] text-[#439DC6]"
                      : "text-[#929398]"
                  }
               
                 `}
                >
                  <img src={Menu.src} />
                  <span
                    className={`${
                      !open && "hidden"
                    } origin-left duration-100   text-lg font-medium font-['Outfit'] flex-1`}
                  >
                    {Menu.title}
                  </span>
                  {Menu.subMenu && open ? (
                    <img
                      className="mr-2 h-4"
                      src={Menu.lock ? ArrowDown : ArrowDown}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {Menu.subMenu && open && (
                  <>
                    {Menu.subMenu?.map((subMenu, index) => (
                      <div
                        onClick={() =>
                          onClickSub(
                            subMenu.id,
                            Menu.id,
                            subMenu.navigation,
                            subMenu.subId,
                            Menu.lock
                          )
                        }
                        className={`ml-4  select-none cursor-pointer text-sm font-medium font-['Outfit'] my-5 flex items-center duration-100 transition-transform${
                          !Menu.open ? " h-0 hidden" : "h-full"
                        } ${
                          subMenu.focus === true
                            ? " text-[#439DC6]"
                            : "text-[#929398]"
                        } `}
                      >
                        <img
                          className="mr-2"
                          src={subMenu.focus ? CircleBlue : Circle}
                        />
                        {subMenu.title}
                      </div>
                    ))}
                  </>
                )}
              </>
            ))}
          </div>
        </div>
        <div></div>
        <div
          className={`mt-5 mb-5 ml-28  duration-300 mr-8 ${
            open ? "ml-[400px] w-[calc(100%-400px)]" : "ml-[120px] w-full "
          } `}
        >
          <Outlet />
        </div>
      </div>
    </LoadingFullPage>
  );
}
