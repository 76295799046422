import React from "react";
import DeleteItem from "../../../../../icons/delete-item.svg";
import DeletePopover from "../../../../../components/Popover/DeletePopover";
export default function Item({ title }) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div className="w-[269px] h-[81px] relative mt-4">
      <div className="w-[269px] h-[81px] bg-orange-500 rounded-[10px] border border-neutral-200 items-center justify-center flex">
        <div className="w-[164px] left-[34px] top-[29px] text-white text-lg font-semibold font-['Outfit']">
          {title}
        </div>
        <div className="w-5 h-5 left-[242px] top-[7px] absolute justify-center items-center inline-flex">
          <div className="w-5 h-5 relative ">
            <DeletePopover
              open={open}
              popoverClose={handleTooltipClose}
              cancelButton={handleTooltipClose}
              //deleteButton={() => deleteQuestions(defaultQuestion, id)}
            >
              <img
                className="hover:opacity-75 cursor-pointer"
                src={DeleteItem}
                onClick={handleTooltipOpen}
              />
            </DeletePopover>
          </div>
        </div>
      </div>
    </div>
  );
}
