import React, { useState } from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";
import Column from "./Column";
import UserAdd from "../../../../../icons/user-plus.svg";
import Question from "../../../../../icons/question.svg";
import Checklist from "../../../../../icons/checklist.svg";
import Modal from "../../../../../components/Modal/Modal";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Button/Button";
import RightIcon from "../../../../../icons/arrow-right.svg";

export default function Module({ changePage, backPage }) {
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const handleClickOpen = (title) => {
    setModalTitle(title);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div>
      <div className="w-full flex items-center ">
        <div className="w-[600px] flex-1 items-center flex flex-col justify-center">
          <div className="grid  grid-cols-3 gap-5">
            <Column
              title={"Kim İçin Yapıyoruz?"}
              subTitle={"(Kullanıcı Kitlesi)"}
              headerIcon={UserAdd}
              addItem={() => handleClickOpen("Kim İçin Yapıyoruz?")}
            />
            <Column
              title={"Nasıl Yapabiliriz?"}
              subTitle={"(Amaç)"}
              headerIcon={Question}
              addItem={() => handleClickOpen("Nasıl Yapabiliriz?")}
            />
            <Column
              title={"Böylelikle"}
              subTitle={"(Sonuç)"}
              headerIcon={Checklist}
              addItem={() => handleClickOpen("Böylelikle")}
            />
          </div>
          <div className="w-full pr-10 ">
            <Stepper
              nextChange={() => changePage()}
              backChange={backPage}
              activeStep={2}
              stepLimit={3}
              //   nextTooltip={
              //     disableState &&
              //     "Lütfen tüm soruları doldurunuz. Tüm sorular doldurulduktan sonra devam edebilirsiniz."
              //   }
            />
          </div>
        </div>
      </div>
      <Modal
        //selectedValue={selectedValue}
        open={open}
        header={false}
        onClose={handleClose}
        title={"Soru Ekle"}
        desc="Lütfen soru eklemek için alt tarafı doldurunuz."
        children={
          <div className="">
            <div className="p-5 flex justify-center items-start flex-col">
              <div className="w-[165px] text-neutral-500 text-lg font-semibold font-['Outfit']">
                {modalTitle}
              </div>
              <div className="w-[273px] opacity-70 text-neutral-500 text-xs font-normal font-['Outfit'] pt-1">
                Lütfen Belirtilen Alana Uygun Açıklamanızı Ekleyiniz
              </div>
              <div className="flex w-full pt-4">
                <div className=" flex-1">
                  <Input
                  //value={addQuestion}
                  // onChange={(e) => setaddQuestion(e.target.value)}
                  />
                </div>
                <div className="ml-4 mt-2">
                  <Button text="Ekle" iconRight={RightIcon} />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}
