import axios from "axios";

const apiUrl = "https://panel.kurumsalgirisimpusulasi.com/api";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export async function subModuleQuestions(subModuleId) {
  const result = axios
    .get(apiUrl + "/submodules/survey/" + subModuleId + "/questions", {
      headers: headers,
      withCredentials: true,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      //   errorToast(error?.response?.data?.messageId);
      return error?.response?.data?.text;
    });

  return result;
}

export async function subModuleSurveyAnswers(subModuleId, answers) {
  const result = axios
    .post(apiUrl + "/submodules/survey/" + subModuleId + "/answers", answers, {
      headers: headers,
      withCredentials: true,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      //   errorToast(error?.response?.data?.messageId);
      return error?.response?.data?.text;
    });

  return result;
}

export async function subModuleCreateNewQuestions(subModuleId, question) {
  const result = axios
    .post(
      apiUrl + "/submodules/survey/" + subModuleId + "/questions",
      question,
      {
        headers: headers,
        withCredentials: true,
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      //   errorToast(error?.response?.data?.messageId);
      return error?.response?.data?.text;
    });

  return result;
}

export async function subModuleDeleteQuestion(subModuleId, questionId) {
  const result = axios
    .delete(
      apiUrl + "/submodules/survey/" + subModuleId + "/questions/" + questionId,
      {
        headers: headers,
        withCredentials: true,
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      //   errorToast(error?.response?.data?.messageId);
      return error?.response?.data?.text;
    });

  return result;
}

export async function subModuleResult(subModuleId) {
  const result = axios
    .get(apiUrl + "/submodules/survey/" + subModuleId + "/results", {
      headers: headers,
      withCredentials: true,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      //   errorToast(error?.response?.data?.messageId);
      return error?.response?.data?.text;
    });

  return result;
}

export async function subModuleSingleAnswer(subModuleId, questionId, answer) {
  const result = axios
    .post(
      apiUrl +
        "/submodules/survey/" +
        subModuleId +
        "/questions/" +
        questionId +
        "/answer",
      answer,
      {
        headers: headers,
        withCredentials: true,
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      //   errorToast(error?.response?.data?.messageId);
      return error?.response?.data?.text;
    });

  return result;
}
