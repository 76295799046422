import React from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
//import { Popover } from "antd";
import { ClickAwayListener, Tooltip } from "@mui/material";
import Check from "../../../../icons/approval.svg";
import DeleteIcon from "../../../../icons/delete-row.svg";
import DeletePopover from "../../../../components/Popover/DeletePopover";

export default function RadioSurvey({
  bgColor,
  question,
  index,
  selectQuetions,
  value,
  id,
  defaultQuestion,
  deleteQuestions,
}) {
  //initial

  const handleChange = (event) => {
    selectQuetions(event.target.value + "", id);
  };

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );

  return (
    <div
      style={{ backgroundColor: bgColor }}
      className={"flex   text-left justify-center items-center"}
    >
      <div className="flex-1 w-[764px] text-neutral-500 text-base font-normal font-['Outfit']  ml-1">
        {index + 1}. &nbsp;{question}
      </div>

      <div>
        <FormControl sx={{ m: 1, width: 347 }} size="small">
          <InputLabel style={{ font: "Outfit" }} id="demo-select-small-label">
            Lütfen seçim yapınız
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={value}
            label="Lütfen seçim yapınız"
            onChange={handleChange}
          >
            <MenuItem value={"Kesinlikle katılmıyorum"}>
              <div className="flex items-center ">
                <div class="w-4 h-4 bg-red-400 mr-2 rounded-full"></div>
                <div>Kesinlikle katılmıyorum</div>
              </div>
            </MenuItem>
            <MenuItem value={"Kısmen katılıyorum"}>
              <div className="flex items-center ">
                <div class="w-4 h-4 bg-orange-400 mr-2 rounded-full"></div>
                <div>Kısmen katılıyorum</div>
              </div>
            </MenuItem>
            <MenuItem value={"Belirli ölçüde katılıyorum"}>
              <div className="flex items-center ">
                <div class="w-4 h-4 bg-amber-400 mr-2 rounded-full"></div>
                <div>Belirli ölçüde katılıyorum</div>
              </div>
            </MenuItem>
            <MenuItem value={"Büyük ölçüde katılıyorum"}>
              <div className="flex items-center ">
                <div class="w-4 h-4 bg-yellow-400 mr-2 rounded-full"></div>
                <div>Büyük ölçüde katılıyorum</div>
              </div>
            </MenuItem>
            <MenuItem value={"Tamamen katılıyorum"}>
              <div className="flex items-center ">
                <div class="w-4 h-4 bg-green-400 mr-2 rounded-full"></div>
                <div>Tamamen katılıyorum</div>
              </div>{" "}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>
        <Tooltip
          placement="left"
          title={
            defaultQuestion
              ? "Bu sorular kaldırılamaz."
              : "Silmek için tıklayınız"
          }
        >
          {!defaultQuestion ? (
            <DeletePopover
              open={open}
              popoverClose={handleTooltipClose}
              cancelButton={handleTooltipClose}
              deleteButton={() => deleteQuestions(defaultQuestion, id)}
            >
              <img
                className="h-5 cursor-pointer"
                src={defaultQuestion ? Check : DeleteIcon}
                //onClick={() => deleteQuestions(defaultQuestion, id)}
                onClick={handleTooltipOpen}
              />
            </DeletePopover>
          ) : (
            <img
              className="h-5 cursor-pointer"
              src={defaultQuestion ? Check : DeleteIcon}
              //onClick={() => deleteQuestions(defaultQuestion, id)}
              onClick={handleTooltipOpen}
            />
          )}
        </Tooltip>
      </div>
    </div>
  );
}
