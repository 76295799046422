import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  spinner: false,
};

export const spinnerSlice = createSlice({
  name: "spinnerSlice",
  initialState,
  reducers: {
    setSpinner: (state, action) => {
      state.spinner = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSpinner } = spinnerSlice.actions;

export default spinnerSlice.reducer;
