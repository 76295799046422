import React from "react";
import AddItem from "../../../../../icons/add-item.svg";
import Item from "./Item";

export default function Column({ headerIcon, title, subTitle, addItem }) {
  return (
    <div className="w-[311px]  ">
      <div className="w-[311px] ounded-[10px] border border-neutral-200 pb-5">
        <div className="w-[311px] h-[93px]  bg-[#439DC6] rounded-tl-[10px] rounded-tr-[10px] border border-blue-500">
          <div className="flex items-center justify-start p-4 relative">
            <div className="pr-2">
              <img src={headerIcon} />
            </div>
            <div className=" ">
              <span className="text-white text-lg font-semibold font-['Outfit']">
                {title}
                <br />
              </span>
              <span className="text-white text-lg font-normal font-['Outfit']">
                {subTitle}
              </span>
            </div>
            <div className="ml-5  cursor-pointer absolute right-4">
              <img
                className="hover:opacity-75 "
                src={AddItem}
                onClick={addItem}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center flex-col justify-center ">
          <Item title="Girişim Pusulası" />
          <Item title="Girişim Pusulası" />
          <Item title="Girişim Pusulası" />
          <Item title="Girişim Pusulası" />
          <Item title="Girişim Pusulası" />

          <Item title="Girişim Pusulası" />
        </div>
      </div>
    </div>
  );
}
