import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import SmallLogo from "../../images/GP-Small-Logo.svg";
export default function LoadingFullPage({ state, children }) {
  return (
    <>
      <div
        className={
          state &&
          " pointer-events-none bg-white items-center justify-center flex w-full h-screen"
        }
      >
        {state && (
          <div className="flex flex-col items-center justify-center">
            <img
              src={SmallLogo}
              className={`animate-spin cursor-pointer w-[130px] duration-500 `}
            />
            {/* <div className="animate-pulse text-[#F1903F] text-2xl font-normal font-['Outfit'] whitespace-nowrap text-ellipsis mt-10">
              Sizin için özelleştiriliyor
            </div> */}
            {/* <CircularProgress sx={{ position: "absolute", top: 0 }} /> */}
          </div>
        )}
        {!state && children}
      </div>
    </>
  );
}
