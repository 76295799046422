import React from "react";

export default function Chechbox({ text, value, onChange, error }) {
  console.error("error: ", error);
  return (
    <div className="flex-1 flex items-center">
      <input
        checked={value}
        onChange={onChange}
        type="checkbox"
        className={`h-4 w-4 text-indigo-600 focus:ring-indigo-500 rounded ${
          error === true ? "border-red-600" : "border-gray-300"
        }`}
      />
      <div
        className={`text-gray-500 flex text-base font-normal font-['Outfit'] ml-2 ${
          error === true ? " text-red-600" : "text-gray-500"
        }`}
      >
        {text}
      </div>
    </div>
  );
}
