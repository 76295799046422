import React from "react";
import Router from "./router/router";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import useWindowDimensions from "./utils/windowUtils";
import NotFound from "./pages/NotFound/NotFound";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import LoadingFullPage from "./components/LoadingFullPage/LoadingFullPage";

function App() {
  const { height, width } = useWindowDimensions();
  console.log("width: ", width);

  return (
    <Provider store={store}>
      <ToastContainer
        position="bottom-center"
        theme="colored"
        style={{ width: "auto" }}
      />

      {width > 600 ? <Router /> : <NotFound />}
    </Provider>
  );
}

export default App;
