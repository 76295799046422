import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { BLUE_TYPE } from "./constants";

export default function Button({
  text,
  onClick,
  className,
  disabled,
  iconRight,
  iconLeft,
  tooltip,
  iconType,
}) {
  return (
    <>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "white",
              borderColor: "#439DC6",
              borderWidth: 2,
            },
          },
        }}
        disableHoverListener={tooltip?.length > 0 ? false : true}
        title={
          <div className="text-base bg-white text-[#439DC6] font-['Outfit']">
            {tooltip}
          </div>
        }
      >
        <button
          type="button"
          onClick={onClick}
          disabled={disabled}
          className={iconType ? iconType : BLUE_TYPE + className}
        >
          <div className="flex items-center justify-center">
            {iconLeft && <img className="mr-2" src={iconLeft} />}
            <div>{text}</div>
            {iconRight && <img className="ml-2" src={iconRight} />}
          </div>
        </button>
      </Tooltip>{" "}
    </>
  );
}
