import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import RightIcon from "../../icons/arrow-right.svg";
import RegisterIcon from "../../icons/register.svg";
import Logo from "../../images/GP-Logo.png";
import { useResetPasswordMutation } from "../../services/ResetPasswordService";
import { errorService } from "../../services/error";
import { toast } from "react-toastify";

export default function ResetPassword() {
  //initial
  const navigate = useNavigate();
  //services
  const [resetPassword, { isSuccess, error, data, isLoading }] =
    useResetPasswordMutation({});
  const [searchParams, setSearchParams] = useSearchParams();
  //states
  const [userPassword, setUserPassword] = useState({
    password: "",
    passwordRequried: "",
  });

  async function resetPasswordUser() {
    const res = await resetPassword({
      token: searchParams.get("t"),
      newPassword: userPassword.password,
    });
  }

  useEffect(() => {
    errorService(error, navigate);
  }, [error]);

  useEffect(() => {
    if (isSuccess === true) {
      toast.success("Şifre değiştirme işlemi başarıyla tamamlanmıştır.");
      setTimeout(() => {
        navigate("/giris");
      }, 3000);
    }
  }, [isSuccess]);

  return (
    <div className=" bg-[#F6F7FA] flex flex-col justify-center items-center w-full h-screen ">
      <div className="-mt-[8%]">
        <div className="flex items-center justify-center ">
          <div className="flex-1">
            <img className="h-[77px] w-[198px] mb-[44px]" src={Logo}></img>
          </div>
          <div className="h-[60px] mb-10">
            <Button
              iconLeft={RegisterIcon}
              className={"py-[16px] px-[35px]"}
              text={"Giriş yap"}
              onClick={() => navigate("/giris")}
            />
          </div>

          <div></div>
        </div>
        <div className="bg-white w-[800px] h-[382px] p-[40px] rounded-[8px] ">
          <form className="space-y-6" action="#" method="POST">
            <div className="text-black text-5xl font-medium font-['Outfit']">
              Şifremi Unuttum
            </div>
            <div className="w-[720px] text-gray-500 text-base font-normal font-['Outfit']">
              Şifrenizi tekrar giriniz.
            </div>

            <div className="flex">
              <div className="flex-1 mr-[8px]">
                <Input
                  name={"Şifre"}
                  placeholder={"Şifrenizi giriniz"}
                  labelName={"Şifre"}
                  type={"password"}
                  autocomplete="current-password"
                  required={true}
                  value={userPassword.password}
                  tooltipPosition={"left"}
                  tooltipTitle={
                    <div className="text-neutral-400 text-sm font-medium font-['outfit'] p-2">
                      <div>Şifre en az 8 karakter olmalıdır.</div>
                      <div>Büyük ve küçük harf kullanılmalıdır.</div>
                      <div>Rakam kullanılmalıdır.</div>
                    </div>
                  }
                  onChange={(e) =>
                    setUserPassword({
                      ...userPassword,
                      password: e.target.value,
                    })
                  }
                />
              </div>

              <div className="flex-1 ml-[8px]">
                <Input
                  name={"Şifre Tekrar"}
                  placeholder={"Şifrenizi tekrar giriniz"}
                  labelName={"Şifre Tekrar"}
                  type={"password"}
                  autocomplete="current-password"
                  required={true}
                  value={userPassword.passwordRequried}
                  tooltipPosition={"right"}
                  tooltipTitle={
                    <div className="text-neutral-400 text-sm font-medium font-['outfit'] p-2">
                      <div>Şifre en az 8 karakter olmalıdır.</div>
                      <div>Büyük ve küçük harf kullanılmalıdır.</div>
                      <div>Rakam kullanılmalıdır.</div>
                    </div>
                  }
                  onChange={(e) =>
                    setUserPassword({
                      ...userPassword,
                      passwordRequried: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="flex items-center justify-between w-full">
              <div className="flex w-full items-start ml-0.5">
                <div className="flex-1 flex items-center "></div>
                <div className=" flex justify-end items-end  ">
                  <Button
                    className={"py-[16px] px-[35px]"}
                    text={"Onayla"}
                    iconRight={RightIcon}
                    disabled={isLoading}
                    onClick={() => resetPasswordUser()}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
