import React, { useState } from "react";
import Header from "../../../../components/Header/Header";
import { useSearchParams } from "react-router-dom";

export default function IdeaGenerationAndEvaluation() {
  //initial
  //services
  //states
  const [pageState, setPageState] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const [headersText, setHeadersText] = useState([
    {
      title: "İnovasyon Yetkinlik Değerlendirmesi",
      subTexts: ["Bilgilendirme"],
    },
    {
      title: "İnovasyon Yetkinlik Değerlendirmesi",
      subTexts: ["Bilgilendirme", "Anket Modülü"],
    },
    {
      title: "İnovasyon Yetkinlik Değerlendirmesi",
      subTexts: ["Bilgilendirme", "Anket Modülü", "Rapor Sonucu"],
    },
  ]);

  return (
    <>
      <Header
        title={headersText[pageState].title}
        subTexts={headersText[pageState].subTexts}
      />
      <div className="w-full flex items-center justify-center mt-[40px]">
        <div
          className="w-[1200px]  bg-white rounded-[10px] border border-neutral-200 mt-2 p-[32px]"
          id="surver-inovation"
        >
          {/* {pageState === 0 && (
            <CompetenceInformation changePage={() => setPageState(1)} />
          )}
          {pageState === 1 && (
            <CompentenceSurvey
              subId={searchParams.get("subId")}
              backPage={() => setPageState(0)}
              changePage={() => setPageState(2)}
              pageState={pageState}
            />
          )}
          {pageState === 2 && (
            <CompetenceGraphic
              subId={searchParams.get("subId")}
              backPage={() => setPageState(1)}
            />
          )} */}
        </div>
      </div>
    </>
  );
}
