import React from "react";
import Stepper from "../../../../components/Stepper/MainStepper";
import Lock from "../../../../icons/lock-white.svg";

export default function Information({ lock, changePage }) {
  console.log("lock: ", lock);
  return (
    <>
      <div className=" text-black text-lg font-semibold font-['Outfit']">
        Değerli Firma Yetkilimiz,
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-neutral-500 text-lg font-normal font-['Outfit']">
          Bazı iş fikirleriniz yeni pazarlara hitap ediyor olabilir. İş
          fikrinizin yeni pazarlarda başarılı olup olamayacağını belirlemeden
          önce üzerinde çalışacağınız yeni pazarı doğru belirlemeniz oldukça
          önemlidir. Bir ürünün, projenin yeni bir pazarda başarı şansı
          potansiyel pazarın çekiciliği ile orantılıdır. Bir pazarın çekiciliği
          genellikle dört faktöre bağlıdır. <br />
          <br />
          Bu faktörleri 1-5 arasında ölçeklendirerek ve belirlediğiniz kriter
          ağırlıklarına göre puanlandırarak bir pazarın çekiciliğini
          hesaplayabilirsiniz. Yurtdışı hedef pazarlar belirlediyseniz ihracat
          faaliyetleri farklı dinamiklere sahip olduğu için ihracat adımında
          sunduğumuz modüllere bakmanızı tavsiye ederiz. <br />
          <br />
        </span>
        <span className="text-neutral-500 text-lg font-semibold font-['Outfit']">
          Haydi gelin şimdi başlayalım! <br />
        </span>
        <span className="text-neutral-500 text-lg font-normal font-['Outfit']">
          {" "}
          <br />
          Bir pazarın çekiciliğini ortaya koymak için şu dört faktöre göz
          gezdirebilirsiniz: 1.Pazara Giriş Zorluk Derecesi, 2. Pazardaki
          Rekabetin Şiddeti, 3. Pazarın Büyüklüğü ve 4. Pazarın Büyüme Hızı.
          Bunlarda ilk iki parametre için sizlere nicel değerlendirme
          yapabileceğiniz bir ölçek sunacağız. Diğer ikisi içinse sizlere
          yönlendirme yaparak o pazar için puanlama yapmanıza destek olacağız.{" "}
        </span>
      </div>
      <div className="w-full flex items-center ">
        <div className="w-[600px] flex-1">
          <div className="w-full pr-10 ">
            <Stepper
              nextTitle={!lock ? "Kilitli" : "Devam"}
              nextDisable={!lock}
              nextChange={changePage}
              activeStep={1}
              stepLimit={3}
              rightIcon={Lock}
              
            />
          </div>
        </div>
      </div>
    </>
  );
}
