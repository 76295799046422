import { ClickAwayListener } from "@mui/base";
import { Tooltip } from "@mui/material";
import React from "react";

export default function DeletePopover({
  children,
  open,
  popoverClose,
  popoverOpen,
  deleteButton,
  cancelButton,
}) {
  return (
    <div>
      <ClickAwayListener onClickAway={popoverClose}>
        <Tooltip
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "white",
                borderColor: "#E5E5E5",
                borderWidth: 1,
                "& .MuiTooltip-arrow": {
                  color: "#C0C0C0",
                },
              },
            },
          }}
          title={
            <div className="text-sm font-normal bg-white text-neutral-500 font-['Outfit'] min-w-[210px]    flex items-center flex-col p-1">
              <div>Silmek istediğinize emin misiniz ?</div>
              <div className="flex p-2 items-center justify-center w-full">
                <button
                  type="button"
                  className="flex-1 text-white bg-[#FF0000] hover:bg-red-400 font-medium rounded-lg px-3 py-2 text-xs text-center mx-1"
                  onClick={() => deleteButton()}
                >
                  Sil
                </button>
                <button
                  type="button"
                  className="flex-1 text-white bg-[#439DC6] hover:bg-[#67aecf] font-medium rounded-lg px-3 py-2 text-xs text-center mx-1"
                  onClick={() => cancelButton()}
                >
                  İptal
                </button>
              </div>
            </div>
          }
          onClose={popoverClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          {children}
        </Tooltip>
      </ClickAwayListener>
    </div>
  );
}
