import React from "react";
import { MdDelete } from "react-icons/md";

export default function Item({
  provided,
  item,
  snapshot,
  columnId,
  deleteItem,
}) {
  function itemColor(columnId) {
    switch (columnId) {
      case "keyPartnerships":
        return "#ff595e";
      case "coreActivities":
        return "#ff924c";
      case "essentialResources":
        return "#ffca3a";
      case "valueProposition":
        return "#c5ca30";
      case "customerRelationship":
        return "#8ac926";
      case "channels":
        return "#52a675";
      case "customerSegment":
        return "#1982c4";
      case "costStructure":
        return "#4267ac";
      case "revenueStream":
        return "#6a4c93";

      default:
        return '"#456C86"';
    }
  }
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        userSelect: "none",
        minHeight: "50px",
        backgroundColor: snapshot.isDragging ? "#263B4A" : itemColor(columnId),
        color: "white",
        ...provided.draggableProps.style,
      }}
    >
      <div className="p-4 ">
        <div className="flex">
          <div className="flex-1">{item.title}</div>
          <MdDelete
            className=" cursor-pointer"
            onClick={() => deleteItem(item.id)}
          />
        </div>

        <div className="mt-2 text-xs/[15px] ">{item.content}</div>
      </div>
    </div>
  );
}
